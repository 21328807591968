import Batch from "@/entities/Batch";

export default class Configuration
{
    static LAYOUT_FRONTOFFICE = 'layout-frontoffice';
    static LAYOUT_BACKOFFICE = 'layout-backoffice';
    static LAYOUT_BACKOFFICE_SIMPLE = 'layout-backoffice-simple';


    getCookieExpiryTimeline()
    {
        return "8d";
    }

    getAppVersion()
    {
        return process.env.VUE_APP_VERSION;
    }

    getProductCategoriesUri()
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_PRODUCT_CATEGORIES_URI;
    }

    getFarmersUri()
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_FARMERS_URI;
    }

    getFarmersTotalUri()
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_FARMERS_TOTAL_URI;
    }

    getFarmerUri(id)
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_FARMER_URI.replace('{id}', id);
    }

    getFarmerLogoUploadUri(id)
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_FARMER_LOGO_UPLOAD_URI.replace('{id}', id);
    }

    getProductsUri(farmerId)
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_PRODUCTS_URI.replace('{farmerId}', farmerId);
    }

    getEnGrossProductsUri(farmerId)
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_EN_GROSS_PRODUCTS_URI.replace('{farmerId}', farmerId);
    }

    getEnGrossProductUri(farmerId, productId)
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_EN_GROSS_PRODUCT_URI.replace('{farmerId}', farmerId).replace('{id}', productId);
    }

    getEnGrossProductImageUploadUri(farmerId, productId)
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_EN_GROSS_PRODUCT_IMAGE_UPLOAD_URI.replace('{farmerId}', farmerId).replace('{id}', productId);
    }

    getProductsForEnGross(farmerId)
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_PRODUCTS_EN_GROSS_URI.replace('{farmerId}', farmerId);
    }

    getProductsUploadUri(farmerId)
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_PRODUCTS_UPLOAD_URI.replace('{farmerId}', farmerId);
    }

    getProductsDownloadUri(farmerId)
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_PRODUCTS_DOWNLOAD_URI.replace('{farmerId}', farmerId);
    }

    getOrderUploadUri(cycleId)
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_UPLOAD_ORDERS_URI.replace('{cycleId}', cycleId);
    }

    getOrdersUri()
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_ORDERS_URI;
    }

    getOrdersExportUri()
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_ORDERS_EXPORT_URI;
    }

    getOrdersSummaryUri()
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_ORDER_SUMMARY_URI;
    }

    getOrderUri(orderId)
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_ORDER_URI.replace('{id}', orderId);
    }

    getOrdersDeleteUri(orderId)
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_DELETE_ORDERS_URI.replace('{id}', orderId);
    }

    getApproveOrdersUri(orderId)
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_APPROVE_ORDERS_URI.replace('{id}', orderId);
    }

    getCollectOrderUri(orderId)
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_COLLECT_ORDER_URI.replace('{id}', orderId);
    }

    getPackOrdersUri(orderId)
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_PACK_ORDERS_URI.replace('{id}', orderId);
    }

    getTokenLoginUri()
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_TOKEN_LOGIN_URI;
    }

    getTokenLogoutUri()
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_TOKEN_LOGOUT_URI;
    }

    getRenewTokenUri()
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_TOKEN_RENEW_URI;
    }

    getReadyItemOrdersUri(orderId, itemId)
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_READY_ITEMS_ORDERS_URI.replace('{orderId}', orderId).replace('{id}', itemId);
    }

    getUploadOrderInvoiceUri(orderId)
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_UPLOAD_ORDER_INVOICE_URI.replace('{orderId}', orderId);
    }

    getOrdersItemUpdateUri(orderId, itemId)
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_ORDERS_ITEMS_UPDATE_URI.replace('{orderId}', orderId).replace('{id}', itemId);
    }

    getOrdersContactUpdateUri(orderId)
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_ORDERS_CONTACT_UPDATE_URI.replace('{id}', orderId);
    }

    getApproveAllOrdersUri()
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_APPROVE_ALL_ORDERS_URI;
    }

    getOrdersEditUri()
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_ORDERS_EDIT_URI;
    }

    getBatchesUri()
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_BATCHES_URI;
    }

    getBatchesSummaryUri()
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_BATCHES_SUMMARY_URI;
    }

    getBatchUri(batchId, version = null)
    {
        switch (version) {
            case Batch.VERSION_DELIVERY:
                return process.env.VUE_APP_ORDER_MANAGEMENT_BATCH_DELIVERY_VERSION_URI.replace('{id}', batchId);
            default:
                return process.env.VUE_APP_ORDER_MANAGEMENT_BATCH_URI.replace('{id}', batchId);
        }
    }

    getBatchesItemUpdateUri(batchId, itemId)
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_BATCHES_ITEMS_UPDATE_URI.replace('{batchId}', batchId).replace('{id}', itemId);
    }

    getBatchesGenerateUri()
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_GENERATE_BATCHES_URI;
    }

    getBatchesApproveUri(batchId)
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_APPROVE_BATCHES_URI.replace('{id}', batchId);
    }
    getSendBatchesToFarmUri(batchId)
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_SEND_TO_FARM_BATCHES_URI.replace('{id}', batchId);
    }

    getBatchesPackUri(batchId)
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_PACK_BATCHES_URI.replace('{id}', batchId);
    }

    getBatchesCollectionReadyUri(batchId)
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_COLLECTION_READY_BATCHES_URI.replace('{id}', batchId);
    }

    getBatchesDeleteUri(batchId)
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_DELETE_BATCHES_URI.replace('{id}', batchId);
    }

    getBatchesPrepackUri(batchId)
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_PREPACK_BATCHES_URI.replace('{id}', batchId);
    }

    getBatchesConfirmUri(batchId)
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_CONFIRM_BATCHES_URI.replace('{id}', batchId);
    }

    getBatchesReConfirmUri(batchId)
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_RECONFIRM_BATCHES_URI.replace('{id}', batchId);
    }

    getBatchesForceReConfirmUri(batchId)
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_FORCE_RECONFIRM_BATCHES_URI.replace('{id}', batchId);
    }

    getBatchesReadyUri(batchId)
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_READY_BATCHES_URI.replace('{id}', batchId);
    }

    getFarmerBatchItemUpdateUri(farmerId, batchId, itemId)
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_FARMER_UPDATE_BATCH_ITEM_URI.replace('{farmerId}', farmerId).replace('{batchId}', batchId).replace('{id}', itemId);
    }

    getUsersUri()
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_USERS_URI;
    }

    getUserUri(userId)
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_USER_URI.replace('{id}', userId);
    }

    getRolesUri()
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_USER_ROLES_URI;
    }

    getCyclesUri()
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_CYCLES_URI;
    }

    getCycleUri(cycleId)
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_CYCLE_URI.replace('{id}', cycleId);
    }

    getFarmerCreateCycleUri(farmerId)
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_FARMER_CYCLE_CREATE_URI.replace('{farmer_id}', farmerId);
    }

    getFarmerDeleteCyclesUri(farmerId)
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_FARMER_CYCLE_CREATE_URI.replace('{farmer_id}', farmerId);
    }

    getFarmerUpdateCycleUri(farmerId, cycleId)
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_FARMER_CYCLE_UPDATE_URI.replace('{farmer_id}', farmerId).replace('{id}', cycleId);
    }

    getActiveCycleUri()
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_CYCLE_ACTIVE_URI;
    }

    getEndActiveCycleUri()
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_CYCLE_ACTIVE_END_URI;
    }

    getStartActiveCycleUri()
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_CYCLE_ACTIVE_START_URI;
    }

    getRecoverPasswordUri()
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_RECOVER_PASSWORD_URI;
    }

    getDeliveryNotesUri()
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_DELIVERY_NOTES_URI;
    }

    getDeliveryNoteUri(id)
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_DELIVERY_NOTE_URI.replace('{id}', id);
    }

    getDriversUri()
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_DRIVERS_URI;
    }

    getGoogleMapsSearchTermUri(term)
    {
        return process.env.VUE_APP_GOOGLE_MAPS_SEARCH_TERM.replace('{term}', term);
    }

    getShipmentUri(deliveryNoteId, shipmentId)
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_SHIPMENT_URI.replace('{id}', deliveryNoteId).replace('{shipmentId}', shipmentId);
    }

    getNotificationsUri(isList = false)
    {
        if (!isList) {
            return process.env.VUE_APP_ORDER_MANAGEMENT_NOTIFICATIONS_URI;
        }

        return process.env.VUE_APP_ORDER_MANAGEMENT_NOTIFICATIONS_LIST_URI;
    }

    getCleanupApplicationUri()
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_APPLICATION_CLEANUP_URI;
    }

    getCleanupOwnApplicationUri()
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_APPLICATION_CLEANUP_OWN_URI;
    }

    getStocksBatchesUri()
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_STOCKS_BATCHES_URI;
    }

    getStockBatchUri(batchId)
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_EDIT_STOCKS_BATCHES_URI.replace('{id}', batchId);
    }

    getGenerateStocksBatchesUri()
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_GENERATE_STOCKS_BATCHES_URI;
    }

    getSendToInProgressStockBatchUri(batchId)
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_SEND_TO_IN_PROGRESS_STOCKS_BATCHES_URI.replace('{id}', batchId);
    }

    getSendToDoneStockBatchUri(batchId)
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_SEND_TO_DONE_STOCKS_BATCHES_URI.replace('{id}', batchId);
    }

    getItemStockBatchUri(batchId, itemId)
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_ITEMS_STOCKS_BATCHES_URI.replace('{id}', batchId).replace('{itemId}', itemId);
    }

    getMigrateStockBatchUri(batchId)
    {
        return process.env.VUE_APP_ORDER_MANAGEMENT_MIGRATE_STOCKS_BATCHES_URI.replace('{id}', batchId);
    }

    static getSingleton()
    {
        if (typeof this.singleton == 'undefined') {
            this.singleton = new Configuration();
        }

        return this.singleton;
    }

    getLocale()
    {
        return "ro-RO";
    }
}
