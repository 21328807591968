<template>
  <div class="modal fade show dark-overlay" tabindex="-1">
    <div class="modal-dialog modal-dialog-md modal-lg modal-dialog-scrollable" :class="{'bg-transparent': transparentBg}">
      <div class="modal-content" :class="{'bg-transparent shadow-none text-shadow-dark border-0': transparentBg}">
        <div class="modal-header" v-if="name || dismissible">
          <h5 v-show="name" class="modal-title">{{ $t(name) }}</h5>
          <h5 v-show="customName" class="modal-title font-weight-semibold">{{ customName }}</h5>
          <button @click="closeModal" v-show="dismissible" type="button" class="close" data-dismiss="modal">&times;</button>
        </div>

        <div class="modal-body" :class="{'text-white': transparentBg}">
          <slot>
            <h6 class="font-weight-semibold">Text in a modal</h6>
            <p>Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.</p>

            <hr>

            <h6 class="font-weight-semibold">Another paragraph</h6>
            <p>Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</p>
            <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.</p>
          </slot>
        </div>

        <div v-if="withFooter || withBlockFooter" v-bind:class="{'d-block': withBlockFooter}" class="modal-footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  emits: ['closemodal'],
  props: {
    dismissible: {
      type: Boolean,
      default: false
    },
    customName: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    withFooter: {
      type: Boolean,
      default: false
    },
    withBlockFooter: {
      type: Boolean,
      default: false,
    },
    transparentBg: {
      type: Boolean,
      default: false,
    }
  },
  mounted() {
    this.toggleModalOpen();
  },
  unmounted() {
    this.closeModal();
  },
  methods: {
    toggleModalOpen: function () {
      document.getElementsByTagName("body")[0].classList.add('modal-open');
    },
    closeModal: function () {
      document.getElementsByTagName("body")[0].classList.remove('modal-open');
      this.$emit('closemodal');
    }
  }
}
</script>

<style scoped>
.modal.show {
  display: block;
}

.modal-dialog {
  top: 5em;
  height: auto;
  background-color: white;
  z-index: 1;
}

.modal-content {
  max-height: calc(100vh - 170px);
  max-height: -webkit-calc(100vh - 170px);
}

.modal:after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  content: '';
}
@media (min-width:576px) {
}

@media (min-width:768px) {
  .modal-dialog-md {
    top: 3em;
  }
}
@media (min-width:992px) {
}

</style>
