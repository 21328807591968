<template>
  <div class="page-title d-flex">
    <h4><a class="cursor-pointer" @click="$router.go(-1)"><i class="icon-arrow-left52 mr-2"></i></a> <span class="font-weight-semibold">{{ category }}</span> - {{ breadcrumb }}</h4>
  </div>
</template>

<script>
export default {
  name: "PageTitle",
  computed: {
    breadcrumb: function () {
      return this.$i18n.t(this.$route.name || "", {additional: this.additional});
    },
    category: function () {
      return this.$i18n.t(this.$route.meta.category || "")
    },
    additional: function () {
      return this.$store.state.breadcrumb;
    }
  }
}
</script>

<style scoped>
</style>