import Pagination from "@/entities/Pagination";

export default class Collection
{
    constructor(items = [], pagination = null) {
        this.items = items;
        if (null !== pagination) {
            this.pagination = pagination;
        } else {
            this.pagination = new Pagination();
        }
    }

    getItems() {
        return this.items;
    }

    getPagination() {
        return this.pagination;
    }

    push(item) {
        this.items.push(item);
    }

    pop() {
        return this.items.pop();
    }

    hasPagination()
    {
        return null !== this.pagination;
    }

    empty() {
        this.items = [];
    }

    isEmpty()
    {
        return this.items.length == 0;
    }

    setPagination(pagination)
    {
        this.pagination = pagination
        return this;
    }

    getSize()
    {
        return this.items.length;
    }

    getTotal()
    {
        return this.hasPagination() ? this.getPagination().getTotal() : this.getSize();
    }

    hasItem(item)
    {
        return this.items.includes(item);
    }

    sum(elementExtractor) {
        return this.items.reduce((a, b) => a + (elementExtractor(b) || 0), 0);
    }

    getFirst()
    {
        if (this.isEmpty()) {
            return null;
        }

        return this.items[0];
    }

    getLast()
    {
        if (this.isEmpty()) {
            return null;
        }

        return this.items[this.getSize()-1];
    }
}
