export default class Pagination {

    totalItems;
    itemsPerPage;
    currentPage;
    totalPages;

    constructor(currentPage = 0, totalItems = 0, itemsPerPage = 0, totalPages = 0) {
        this.totalItems = totalItems;
        this.itemsPerPage = itemsPerPage;
        this.currentPage = currentPage;
        this.totalPages = totalPages;
    }

    getTotal() {
        return this.totalItems;
    }

    getItemsPerPage() {
        return this.itemsPerPage;
    }

    getCurrentPage() {
        return Number.parseInt(this.currentPage);
    }

    getTotalPages() {
        return Number.parseInt(this.totalPages);
    }

    prev(nrPagesToJump) {
        let page = this.getCurrentPage() - Number.parseInt(nrPagesToJump);
        return page > 0 ? page : 1;
    }
    next(nrPagesToJump) {
        let page = this.getCurrentPage() + Number.parseInt(nrPagesToJump);
        return page > this.getTotalPages() ? this.getTotalPages()  : page
    }
    isCurrentPage(page){
        return this.getCurrentPage() == Number.parseInt(page);
    }
}