import { createApp } from 'vue'

import App from '@/App'
import verdeStore from '@/vuex'
import router from "@/router";
import i18n from "@/i18n";
import LoadScript from 'vue-plugin-load-script';
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueClipboard from 'vue3-clipboard'
import { VueCookieNext } from 'vue-cookie-next'
import Configuration from "@/entities/Configuration";

VueCookieNext.config({ expire: Configuration.getSingleton().getCookieExpiryTimeline() })

createApp(App)
    .use(i18n)
    .use(router)
    .use(LoadScript)
    .use(verdeStore)
    .use(VueCookieNext)
    .use(VueClipboard, { autoSetContainer: true, appendToBody: true })
    .use(VueAxios, axios)
    .mount('#app')
