export default class Packing {
    packing;

    constructor(packing) {
        this.packing = packing;
    }

    getId()
    {
        return this.packing.id;
    }

    getName()
    {
        return this.packing.name;
    }

    getValue()
    {
        return this.packing.value || 1;
    }

    getOwner()
    {
        return this.packing.owner;
    }

    isFarmer()
    {
        return this.getOwner() === 'farmer';
    }

    isCustomer()
    {
        return this.getOwner() === 'customer';
    }
}