export default class Changeable {
    changed = false;

    setChanged(changed)
    {
        this.changed = changed;
    }

    isChanged()
    {
        return this.changed;
    }
}