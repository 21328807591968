export default class EmittedEvent {

    type;
    payload;

    constructor(type, payload = null) {
        this.type = type;
        this.payload = payload;
    }

    getType()
    {
        return this.type;
    }

    getPayload()
    {
        return this.payload;
    }

    hasPayload()
    {
        return null !== this.payload;
    }
}