<template>
  <i v-if="simple" class="icon-spinner2 spinner"></i>
  <div v-else v-show="loading" class="upload alert ml-md-2 mr-md-auto text-center"><i class="icon-spinner2 spinner icon-3x"></i></div>
</template>

<script>
export default {
  name: "Spinner",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    simple: {
      type: Boolean,
      default: false,
    }
  }
}
</script>

<style scoped>
</style>
