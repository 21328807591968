<template>
  <div class="dropdown-menu dropdown-content wmin-300">
    <div class="dropdown-content-header">
      <span class="font-weight-semibold">{{ $t('notifications.list.latest') }}</span>
    </div>

    <div class="dropdown-content-body dropdown-scrollable">
      <ul v-if="notifications.isLoaded()" class="media-list">
        <NotificationItem v-for="notification in notifications.getItems()" :notification="notification" :key="notification.getId()"/>
      </ul>
      <Loading v-else />
    </div>

    <div v-if="false" class="dropdown-content-footer bg-light">
      <a href="#" class="text-grey mr-auto">All activity</a>
      <div>
        <a href="#" class="text-grey" data-popup="tooltip" title="Clear list"><i class="icon-checkmark3"></i></a>
        <a href="#" class="text-grey ml-2" data-popup="tooltip" title="Settings"><i class="icon-gear"></i></a>
      </div>
    </div>
  </div>
</template>

<script>
import NotificationItem from "@/components/notification/NotificationItem";
import Pagination from "@/entities/Pagination";
import Notification from "@/entities/Notification";
import LoadableCollection from "@/entities/LoadableCollection";
import Loading from "@/components/elements/Loading";
import {AuthorizationMixin} from "@/mixins/GeneralMixin";

export default {
  name: "NotificationList",
  components: {Loading, NotificationItem},
  mixins: [AuthorizationMixin],
  data: function() {
    return  {
      notifications: new LoadableCollection(),
    }
  },
  beforeMount() {
    let that = this;
    this.$router.beforeEach(() => {
        that.getNotifications();
    });
  },
  mounted() {
    this.getNotifications();
  },
  methods: {
    getNotifications: function (page = 1) {
      if (!this.isLoggedInUserAndValid) {
        return;
      }
      let that = this;
      let filterParams = {
        page: page,
      };

      this.notifications.unloadAndEmpty();
      this.axios.get(this.$store.state.config.getNotificationsUri(true), {params: filterParams}).then(
          response => {
            if (!response || typeof response === undefined) {
              return;
            }
            let content = response.data;
            that.notifications.setPagination(
                new Pagination(
                    content.data.current_page,
                    content.data.total,
                    content.data.per_page,
                    content.data.last_page
                )
            );

            content.data.data.forEach(function (notification) {
              that.notifications.push(new Notification(notification));
            });

            that.notifications.loaded();
          }
      ).catch(
          error => {
            console.warn(error);
          }
      );
    },
  },
}
</script>

<style scoped>

</style>