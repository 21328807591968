import Loadable from "@/entities/Loadable";
import Changeable from "@/entities/Changeable";
import Moment from "@/entities/Moment";

export default class Cycle extends Loadable
{
    cycle;
    changed;

    id;
    name;
    active;
    orderBatch;
    sendToFarmBatch;
    prepackBatch;
    packBatch;
    sendToCollectionBatch;
    collectBatch;
    deliverOrder;

    constructor(cycle = null) {
        super();
        this.changed = new Changeable();
        if (null !== cycle) {
            this.cycle = cycle;
            this.id = cycle.id;
            this.name = cycle.name;
            this.active = cycle.is_active;
            this.orderBatch = new Moment(cycle.batch_order);
            this.sendToFarmBatch = new Moment(cycle.send_to_farm_batch);
            this.prepackBatch = new Moment(cycle.prepack_batch);
            this.packBatch = new Moment(cycle.pack_batch);
            this.sendToCollectionBatch = new Moment(cycle.send_to_collection_batch);
            this.deliverOrder = new Moment(cycle.deliver_order);
            this.collectBatch = new Moment(cycle.collect_batch);
        } else {
            this.orderBatch = new Moment();
            this.sendToFarmBatch = new Moment();
            this.prepackBatch = new Moment();
            this.packBatch = new Moment();
            this.sendToCollectionBatch = new Moment();
            this.deliverOrder = new Moment();
            this.collectBatch = new Moment();
        }
    }

    setId(id)
    {
        this.cycle.id = id;
    }

    getId()
    {
        return this.cycle.id;
    }

    isActive()
    {
        return !!this.active;
    }

    getOrderBatch()
    {
        return this.orderBatch;
    }

    getSendToFarmBatch()
    {
        return this.sendToFarmBatch;
    }

    getPrepackBatch()
    {
        return this.prepackBatch;
    }

    getPackBatch()
    {
        return this.packBatch;
    }

    getSendToCollectionBatch()
    {
        return this.sendToCollectionBatch;
    }

    getCollectBatch()
    {
        return this.collectBatch;
    }

    getDeliverOrder()
    {
        return this.deliverOrder;
    }

    isChanged()
    {
        return this.changed.isChanged();
    }

    setChanged(changed)
    {
        this.changed.setChanged(changed);
    }

    getName()
    {
        return this.name
    }

    setName(name)
    {
        this.name = name;
        this.setChanged(true);
    }

    getTotalOrders()
    {
        return this.cycle.total_orders;
    }

    canDelete()
    {
        return !!this.cycle.can_delete;
    }
}