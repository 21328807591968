import Collection from "@/entities/Collection";
import Loadable from "@/entities/Loadable";

export default class LoadableCollection extends Collection {
    constructor(items = [], pagination = null) {
        super(items, pagination);
        this.loadable = new Loadable();
    }

    isLoaded()
    {
        return this.loadable.isLoaded();
    }

    loaded()
    {
        this.loadable.setLoaded(true);
    }

    unload()
    {
        this.loadable.setLoaded(false);
    }

    unloadAndEmpty()
    {
        this.unload();
        this.empty();
    }
}