<template>
    <div class="d-flex">
      <div class="breadcrumb">
        <router-link v-show="hasHome" :to="{name: 'general.breadcrumb.dashboard'}" class="breadcrumb-item">
          <i class="icon-home2 mr-2"/>
          {{ $t('general.breadcrumb.home') }}
        </router-link>
        <router-link v-bind:key="breadcrumb.link" class="breadcrumb-item" v-bind:class="breadcrumb.link ? '':'active disabled'" v-for="breadcrumb in breadcrumbs" :to="{name: breadcrumb.link}"> {{ $t(breadcrumb.name, {additional: additional}) }}</router-link>
      </div>
    </div>
</template>

<script>
export default {
  name: 'Breadcrumb',
  props: {
    hasHome: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      breadcrumbs: [],
    }
  },
  computed: {
    additional: function () {
      return this.$store.state.breadcrumb;
    }
  },
  watch: {
    $route: {
      handler: function () {
        this.breadcrumbs = this.$route.meta.breadcrumb;
      }
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.active.disabled {
  color: currentColor;
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
}
</style>
