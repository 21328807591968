<template>
  <button type="button" class="btn" v-bind:class="buttonStyle()">
    <slot>
      <b v-if="left"><i v-bind:class="left"></i></b>
      <span v-if="addonLeft" v-html="addonLeft"/>
      <span :class="textStyle">{{ $t(name) }}</span>
      <span v-if="textRight" v-html="textRight"/>
      <span v-if="addonRight" v-html="addonRight"/>
      <b v-if="right"><i v-bind:class="right"></i></b>
    </slot>
  </button>
</template>

<script>
export default {
  name: "Button",
  props: {
    name: {
      type: String,
      required: true
    },
    textRight: {
      type: String,
      required: false
    },
    type: {
      type: String,
      default: ""
    },
    overwriteTextStyle: {
      type: String,
      default: "",
    }
  },
  data() {
    return {
      left: '',
      right: '',
      addonLeft: '',
      addonRight: '',
      textStyle: '',
    }
  },
  methods: {
    buttonStyle: function () {
      switch (this.type) {
        case 'upload':
          this.left = 'icon-upload';
          return 'btn-info btn-labeled btn-labeled-left';
        case 'download':
          this.left = 'icon-download';
          return 'btn-info btn-labeled btn-labeled-left';
        case 'confirm':
          this.left = 'icon-checkmark2';
          return 'btn-success btn-labeled btn-labeled-left';
        case 'cancel':
          this.left = 'icon-cross2';
          return 'btn-warning btn-labeled btn-labeled-left';
        case 'cancel-discreet':
          this.left = 'icon-cross2';
          return 'btn-light btn-labeled btn-labeled-left';
        case 'confirm-mb-0':
          this.left = 'icon-checkmark2';
          return 'btn-success btn-labeled btn-labeled-left mb-0';
        case 'success':
          return 'btn-success';
        case 'send':
          this.left = 'icon-paperplane';
          return 'btn-success btn-labeled btn-labeled-left';
        case 'modify':
          this.left = 'icon-pencil';
          return 'btn-success btn-labeled btn-labeled-left';
        case 'edit':
          this.left = 'icon-pencil';
          return 'btn-warning';
        case 'edit_labeled':
          this.left = 'icon-pencil';
          return 'btn-warning btn-labeled btn-labeled-left';
        case 'add':
          this.left = 'icon-add';
          return 'btn-danger';
        case 'add_labeled':
          this.left = 'icon-add';
          return 'btn-danger btn-labeled btn-labeled-left';
        case 'add-mb-0':
          this.left = 'icon-add';
          return 'btn-success btn-labeled btn-labeled-left mb-0';
        case 'delete':
          this.left = 'icon-cross3';
          return 'btn-danger';
        case 'delete_labeled':
          this.left = 'icon-cross3';
          return 'btn-danger btn-labeled btn-labeled-left';
        case 'partial_confirm':
          this.left = 'icon-warning';
          return 'btn-warning btn-labeled btn-labeled-left';
        case 'copy':
          this.left = 'icon-copy4';
          return 'btn-light btn-labeled btn-labeled-left';
        case 'eye':
          this.left = 'icon-eye2';
          return 'btn-warning btn-labeled btn-labeled-left';
        case 'show':
          this.left = 'icon-eye2';
          return 'btn-light btn-labeled btn-labeled-left';
        case 'hide':
          this.left = 'icon-eye-blocked2';
          return 'btn-light btn-labeled btn-labeled-left';
        case 'next':
          this.right = 'icon-arrow-right14';
          return 'btn-light btn-labeled btn-labeled-right';
        case 'continue':
          this.right = 'icon-arrow-right14';
          return 'btn-success btn-labeled btn-labeled-right';
        case 'search':
          this.left = 'icon-search4';
          return 'btn-success btn-labeled btn-labeled-left';
        case 'prev':
          this.left = 'icon-arrow-left13';
          return 'btn-light btn-labeled btn-labeled-left';
        case 'link-next':
          this.textStyle = this.overwriteTextStyle ? this.overwriteTextStyle : 'd-none d-sm-inline';
          this.addonRight = "&nbsp; &rarr;";
          return 'btn-light page-link';
        case 'link-prev':
          this.addonLeft = "&larr; &nbsp;";
          this.textStyle = this.overwriteTextStyle ? this.overwriteTextStyle : 'd-none d-sm-inline';
          return 'btn-light page-link';
        case 'link':
          return 'btn-light page-link';
        default:
          return 'btn-light';
      }
    },
  }
}
</script>

<style scoped>

/*
.btn.btn-danger.w-100.btn-labeled-left span {
  margin-left: -36px;
}

.btn.w-100.btn-labeled-right span {
  margin-right: -36px;
}
*/
.btn-success.verde {
  background-color: #17393a;
}

.btn-labeled {
  display: flex;
  justify-content: center;
  padding: 0px;
  align-items: stretch;
}
.btn-labeled > b {
  position: relative;
  display: flex;
  align-items: center;
  top: initial;
  left: initial;
  right: initial;
}
.btn-labeled > span {
  flex-grow: 1;
  padding: .4375rem .875rem;
}

</style>
