import Changeable from "@/entities/Changeable";

export default class Address extends Changeable
{
    address;

    constructor(address = null) {
        super();
        if (null === address) {
            this.address = {};
            return;
        }
        this.address = address;
    }

    getFullAddress()
    {
        return this.address.raw;
    }

    setFullAddress(address)
    {
        this.address.raw = address;
        this.setChanged(true);
    }

    isSet()
    {
        return typeof this.address.raw !== 'undefined';
    }
}