import Farmer from "@/entities/Farmer";
import Collection from "@/entities/Collection";
import Item from "@/entities/Item";
import Moment from "@/entities/Moment";
import Shipment from "@/entities/Shipment";
import Cycle from "@/entities/Cycle";

export default class Batch {
    static VERSION_DELIVERY = 'delivery';

    static editableStatuses = ['new', 'approved', 'sent_to_farm', 'confirmed', 'prepacking'];
    static qtyUpdateStatuses = ['new', 'approved', 'confirmed'];
    static deletableStatuses = ['new', 'approved'];
    static notLimitedStatuses = ['new'];
    static finalStatuses = ['collected', 'not_collected', 'partial_collected'];
    static readyForCollection = ['collection_ready', 'collected', 'partial_collected', 'not_collected'];

    batch;
    farm;
    items;
    orders;
    confirmedOrders;
    readyness = false;
    moments;
    updatedMoments;
    shipment;
    cycle;
    has_shipment;

    constructor(batch) {
        this.batch = batch;
        this.cycle = new Cycle(batch.cycle);
        this.moments = new Collection();
        this.updatedMoments = new Collection();
        this.farm = new Farmer(batch.farmer);
        this.items = batch.items ? new Collection(batch.items.map(item => new Item(item))) : new Collection();
        this.shipment = batch.shipment ? new Shipment(batch.shipment) : null;
        this.orders = new Collection();
        this.confirmedOrders = new Collection();
        this.has_shipment = batch.has_shipment;

        if (batch.orders) {
            batch.orders.forEach(item => !this.orders.hasItem(item.order_id) ? this.orders.push(item.order_id) : null);
        }
        if (batch.moments) {
            batch.moments.forEach(moment => this.moments.push(new Moment(moment)));
        }
        if (batch.confirmed_orders) {
            batch.confirmed_orders.forEach(order => !this.confirmedOrders.hasItem(order.id) ? this.confirmedOrders.push(order.id) : null)
        }
    }

    hasOrdersWithoutInvoice() {
        return !!this.batch.has_orders_without_invoice;
    }
    getCycle() {
        return this.cycle;
    }

    hasShipment() {
        return this.has_shipment;
    }

    getShipment() {
        return this.shipment;
    }

    getId() {
        return this.batch.id;
    }

    getFarm() {
        return this.farm;
    }

    getTotalPriceWithVat() {
        return this.batch.total_price_with_vat;
    }

    getCurrency() {
        return this.batch.currency;
    }

    getStatus() {
        return this.batch.status;
    }

    getFarmerCanDeliver() {
        return this.batch.farmer_can_deliver;
    }

    isApproved() {
        return 'approved' === this.getStatus();
    }

    isFinished() {
        return Batch.finalStatuses.includes(this.getStatus());
    }

    isNew() {
        return 'new' === this.getStatus();
    }

    isConfirmed() {
        return 'confirmed' === this.getStatus();
    }

    isReConfirmed() {
        return 're_confirmed' === this.getStatus();
    }

    isSentToFarm() {
        return 'sent_to_farm' === this.getStatus();
    }

    isPrepacking() {
        return 'prepacking' === this.getStatus();
    }

    isPacking() {
        return 'packing' === this.getStatus();
    }

    isEditable() {
        return Batch.editableStatuses.includes(this.getStatus());
    }

    isQtyUpdatetableStatuses() {
        return Batch.qtyUpdateStatuses.includes(this.getStatus());
    }

    getComment() {
        return this.batch.comments;
    }

    hasComment() {
        return typeof this.batch.comments !== 'undefined' && this.batch.comments !== null;
    }

    getItemCollection() {
        return this.items;
    }

    getItems() {
        return this.items.getItems();
    }

    getTotalVat() {
        return this.batch.total_vat;
    }

    getItemByProductId(productId) {
        let items = this.getItems().filter(item => item.getProduct().getId() === productId);
        if (items.length !== 1) {
            return null;
        }
        return items.pop();
    }

    isDeletable() {
        return Batch.deletableStatuses.includes(this.getStatus());
    }

    hasChangedItems() {
        return this.getChangedItems().length > 0;
    }

    getChangedItems() {
        return this.items.getItems().filter(item => item.isChanged());
    }

    hasAllItemsReviewed() {
        return this.items.getItems().filter(item => item.isReviewedAt()).length == this.items.getSize();
    }

    hasAllItemsConfirmed() {
        return this.items.getItems().filter(item => item.isConfirmedAt()).length == this.items.getSize();
    }

    getOrderCollection() {
        return this.orders;
    }

    getConfirmedOrdersCollection() {
        return this.confirmedOrders;
    }

    getOrders() {
        return this.orders.getItems();
    }

    getConfirmedOrders() {
        return this.confirmedOrders.getItems();
    }

    isCollectionReady() {
        return 'collection_ready' === this.getStatus();
    }

    isCollected()
    {
        return Batch.finalStatuses.includes(this.getStatus());
    }

    setReadyness(readyness) {
        this.readyness = readyness;
    }

    isReady() {
        return this.readyness;
    }

    isAwaitingReadyness() {
        return this.isReConfirmed() && !this.isReady();
    }

    isProcessable() {
        return this.isNew() || this.isApproved() || this.isConfirmed() || !this.isAwaitingReadyness();
    }

    canShowStatusModal() {
        return this.isSentToFarm() || this.isPrepacking() || this.isPacking();
    }

    getTotalBulk() {
        let total = 0;
        this.items.getItems().forEach((item) => {
            if (item.isBulk()) {
                total += item.getRealQty();
            }
        });

        return total;
    }

    getTotalPacked() {
        let total = 0;
        this.items.getItems().forEach((item) => {
            if (!item.isBulk()) {
                total += item.getRealQty();
            }
        });

        return total;
    }

    isLimited() {
        return !Batch.notLimitedStatuses.includes(this.getStatus());
    }

    getCollectingMoments() {
        return this.moments.getItems().filter((moment) => moment.isCollecting());
    }

    hasPackingMoments() {
        return this.getPackingMoments().length > 0;
    }

    hasCollectingMoments() {
        return this.getCollectingMoments().length > 0;
    }

    getPackingMoments() {
        return this.moments.getItems().filter((moment) => moment.isPacking());
    }

    getCollectingHours() {
        return this.getCollectingMoments().map((moment) => moment.getTime());
    }

    getItemsWithQty() {
        return this.getItems().filter(item => item.getQty() > 0);
    }

    isReadyForCollection() {
        return Batch.readyForCollection.includes(this.getStatus());
    }

    isNotCollected() {
        return "not_collected" === this.getStatus();
    }

    getViewByStatus() {
        let map = {
            "sent_to_farm": "frontoffice.breadcrumb.farmers.batch",
            "confirmed": "frontoffice.breadcrumb.farmers.batch",
            "prepacking" : "frontoffice.breadcrumb.farmers.prepack",
            "re_confirmed" : "frontoffice.breadcrumb.farmers.prepack",
            "packing": "frontoffice.breadcrumb.farmers.pack",
            "collection_ready": "frontoffice.breadcrumb.farmers.pack",
            "collected": "frontoffice.breadcrumb.farmers.pack",
            "partial_collected": "frontoffice.breadcrumb.farmers.pack",
            "not_collected": "frontoffice.breadcrumb.farmers.pack",
        }

        if (map[this.getStatus()] === undefined) {
            return null;
        }

        return map[this.getStatus()];
    }
}
