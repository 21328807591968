import EmittedEvent from "@/entities/EmittedEvent";

export const LoadingModalMixin = {
    data() {
        return {
            initialise: false,
        }
    },
    computed: {
        isLoading: function () {
            return this.$store.state.isLoading;
        }
    },
    watch: {
        isLoading: {
            handler: function (status) {
                if (!status) {
                    this.onLoadingComplete();
                    this.initialise = false;
                } else {
                    this.onLoadingStart();
                }
            }
        },
    },
    methods: {
        onLoadingComplete: function () {},
        onLoadingStart: function () {},
        queueJob()
        {
            this.$store.commit('queueJob');
        },
        completeJob(initialise = false)
        {
            this.$store.commit('completeJob');
            this.initialise = initialise;
        },
    },
}

export const ConfirmationModalMixin = {
    data() {
        return {
            confirmedEvent: null,
        }
    },
    computed: {
        needsConfirmation: function () {
            return null !== this.confirmedEvent;
        },
        confirmationType: function () {
            return this.needsConfirmation ? this.confirmedEvent.getType() : '';
        }
    },
    methods: {
        closeConfirmation: function() {
            this.confirmedEvent = null;
        },
        askConfirmation: function (emittedEvent) {
            if (typeof emittedEvent == "string") {
                emittedEvent = new EmittedEvent(emittedEvent);
            }
            this.confirmedEvent = emittedEvent;
        },
        notifyForAskConfirmation: function (emittedEvent) {
            this.askConfirmation(emittedEvent);
            this.$emit('askConfirmation', this.confirmedEvent);
            this.closeConfirmation();
        },
        confirmed: function () {
            this.closeConfirmation();
        },
    }
}
