<template>
  <li class="media">
    <div class="mr-3">
      <a href="#" class="btn bg-success-400 rounded-round btn-icon"><i class="icon-notebook"></i></a>
    </div>

    <div class="media-body">
      {{ notification.getText() }}
      <div class="font-size-sm text-muted mt-1">{{ formatDateTime(notification.getCreatedAt()) }}</div>
    </div>
  </li>
</template>

<script>
import {DatesMixin} from "@/mixins/GeneralMixin";

export default {
  name: "NotificationItem",
  mixins: [DatesMixin],
  props: {
    notification: Object,
  }
}
</script>

<style scoped>

</style>