import Changeable from "@/entities/Changeable";
import Driver from "@/entities/Driver";
import Order from "@/entities/Order";
import Collection from "@/entities/Collection";
import Moment from "@/entities/Moment";
export default class Shipment extends Changeable
{
    shipment;
    batch;
    batchId;
    orderId;
    order;
    driver;
    moments;

    qualityIssues;
    is_packing_ok = true;

    constructor(shipment = null) {
        super();
        this.moments = new Collection();
        if (null !== shipment) {
            this.shipment = shipment;
            this.qualityIssues = shipment.quality_issues;
            this.is_packing_ok = !!shipment.is_packing_ok;
            this.driver = new Driver(shipment.driver);
            shipment.moments.forEach((moment) => this.moments.push(new Moment(moment)))

            if (this.isBatch()) {
                this.batchId = shipment.batch_id;
            }
            if (this.isOrder()) {
                this.order = new Order(shipment.order);
                this.orderId = shipment.order.id;
            }
        }
    }

    getId()
    {
        return this.shipment.id;
    }

    getAwb()
    {
        return this.shipment.awb;
    }

    isPackingOk()
    {
        return !!this.is_packing_ok;
    }

    isBatch()
    {
        return this.shipment.is_batch === true;
    }

    isOrder()
    {
        return this.shipment.is_order === true;
    }

    getBatch()
    {
        return this.batch;
    }

    getOrder()
    {
        return this.order;
    }

    getDriver()
    {
        return this.driver;
    }

    isCollected()
    {
        return this.moments.getItems().filter((moment) => moment.isCollecting()).length > 0;
    }

    isDelivered()
    {
        return this.moments.getItems().filter((moment) => moment.isDelivery()).length > 0;
    }

    isPhaseComplete()
    {
        return this.isCollected() || this.isDelivered();
    }

    getQualityIssues()
    {
        return this.qualityIssues;
    }

    getMoments()
    {
        return this.moments;
    }

    getBatchId()
    {
        return this.batchId;
    }

    getOrderId()
    {
        return this.orderId;
    }
}