<template>
  <Modal v-show="!isLoading" @closemodal="$emit('closemodal')" :with-footer="true" :dismissible="true">
    <template v-slot:default>
      <Card :title="title" :with-footer="false">
        <template v-slot:default>
          <div v-if="hasSameAccessAsLoggedInUser" class="text-warning mb-2">{{ $t('users.modal.same_roles_editing_not_allowed') }}.</div>
          <div class="col-lg-10">
            <div class="form-group">
              <TextInput v-bind:disabled="user.isLoaded() && hasSameAccessAsLoggedInUser" :reference="user" @input="setName" :value="user.getName()" :placeholder="$t('users.modal.placeholder.name')" v-bind:class="v$.user.name.$error && 'error'" />
              <div class="input-errors" v-for="(error, index) of v$.user.name.$errors" :key="index">
                <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
              </div>
            </div>
            <div class="form-group">
              <TextInput v-bind:disabled="user.isLoaded()" :reference="user" @input="setEmail" :value="user.getEmail()" :placeholder="$t('users.modal.placeholder.email')" v-bind:class="v$.user.email.$error && 'error'" />
              <div class="input-errors" v-for="(error, index) of v$.user.email.$errors" :key="index">
                <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
              </div>
            </div>
            <div class="form-group">
              <Select2 v-bind:disabled="canDisableFields" :model-value="user.roles" @select="setRoles($event)" :options="canDisableFields ? roles : allowed_roles" :settings="roleSelect2settings" v-bind:class="v$.user.roles.$error && 'error'"/>
              <div class="input-errors" v-for="(error, index) of v$.user.roles.$errors" :key="index">
                <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
              </div>
            </div>
            <div v-if="user.isOfTypeFarmer()" class="form-group">
              <Toggle v-bind:disabled="user.isLoaded() && user.getId() === loggedInUser.getId()" checked_label="users.modal.farmer_driver_checked" unchecked_label="users.modal.farmer_driver_unchecked" @update="toggleIsDriver" :checked="user.isOfTypeDriver()" />
            </div>
            <div class="form-group">
              <label>{{ $t('users.modal.placeholder.farms') }}</label>
              <Select2 v-bind:disabled="user.isLoaded() && hasSameAccessAsLoggedInUser" :model-value="user.farms.map(farm => farm.id)" @select="setFarms($event)" :options="farms" :settings="farmSelect2settings" v-bind:class="v$.user.farms.$error && 'error'"/>
              <div class="input-errors" v-for="(error, index) of v$.user.farms.$errors" :key="index">
                <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
              </div>
            </div>
            <div v-if="user.isOfTypeFarmer()" class="form-group">
              <TextInput :reference="user" @input="setLoginToken" :value="user.getLoginToken()" :placeholder="$t('users.modal.placeholder.farm_token')" v-bind:class="v$.user.loginToken.$error && 'error'" />
              <span class="form-text text-muted text-right">*{{ $t('users.modal.token_info') }}</span>
              <div class="input-errors" v-for="(error, index) of v$.user.loginToken.$errors" :key="index">
                <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
              </div>
            </div>
            <div class="form-group">
              <TextInput v-bind:disabled="user.isLoaded() && hasSameAccessAsLoggedInUser" v-if="user.isLoaded()" type="password" :reference="user" @input="setPassword" :value="user.getPassword()" :placeholder="$t('users.modal.placeholder.password')" v-bind:class="v$.user.password.$error && 'error'" />
              <TextInput v-bind:disabled="user.isLoaded() && hasSameAccessAsLoggedInUser" v-else type="password" :reference="user" @input="setPassword" :value="user.getPassword()" :placeholder="$t('users.modal.placeholder.new_password')" v-bind:class="v$.user.password.$error && 'error'" />
              <div class="input-errors" v-for="(error, index) of v$.user.password.$errors" :key="index">
                <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
              </div>
            </div>
            <div class="form-group">
              <TextInput v-bind:disabled="user.isLoaded() && hasSameAccessAsLoggedInUser" v-if="user.isLoaded()" type="password" :reference="user" @input="setCoPassword" :value="user.getCoPassword()" :placeholder="$t('users.modal.placeholder.copassword')" v-bind:class="v$.user.copassword.$error && 'error'" />
              <TextInput v-bind:disabled="user.isLoaded() && hasSameAccessAsLoggedInUser" v-else type="password" :reference="user" @input="setCoPassword" :value="user.getCoPassword()" :placeholder="$t('users.modal.placeholder.new_copassword')" v-bind:class="v$.user.copassword.$error && 'error'" />
              <div class="input-errors" v-for="(error, index) of v$.user.copassword.$errors" :key="index">
                <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
              </div>
            </div>
            <div class="form-group">
              <Toggle v-bind:disabled="canDisableFields" @update="setIsActive" :checked="user.isActive()" />
            </div>
          </div>
        </template>
      </Card>
      <NotificationAlert :notifications="notification" />
    </template>
    <template v-slot:footer>
      <Button v-bind:disabled="canDisableButtons" @click="createUser" class="mr-auto" v-if="!user.isLoaded()" :name="$t('users.buttons.create')" type="confirm"></Button>
      <Button v-bind:disabled="canDisableButtons" @click="updateUser" class="mr-auto" v-else :name="$t('users.buttons.update')" type="confirm"></Button>
    </template>
  </Modal>
</template>

<script>
import Card from "@/components/elements/Card";
import Modal from "@/components/elements/Modal";
import Button from "@/components/elements/Button";
import Toggle from "@/components/elements/Toggle";
import TextInput from "@/components/elements/TextInput";
import {LoadingModalMixin} from "@/mixins/ModalMixin";
import {AuthorizationMixin, ValidationsMixin, NotificationsMixin} from "@/mixins/GeneralMixin";
import useVuelidate from '@vuelidate/core'
import { email, minLength, required, requiredIf } from '@vuelidate/validators'
import NotificationAlert from "@/components/elements/NotificationAlert";
import Select2 from 'vue3-select2-component';
import {ScrollMixin} from "@/mixins/ViewMixin";
import User from "@/entities/User";

const sameAsPassswordValidator = (value, vm) => vm.user.password === value;
const farmerHasOneFarm = (value, vm) => vm.user.farms.length === 1 || !vm.user.isOfTypeFarmer();
const customFarmerRoleMaxLength = (value, vm) => (vm.user.isOfTypeFarmer() && vm.user.roles.length < 3) || (vm.user.roles.length < 2);

export default {
  setup () {
    return { v$: useVuelidate() }
  },
  name: "UserModal",
  components: {NotificationAlert, TextInput, Toggle, Button, Modal, Card, Select2},
  mixins: [LoadingModalMixin, AuthorizationMixin, ValidationsMixin, NotificationsMixin, ScrollMixin],
  emits: ['closemodal'],
  validations: {
    user: {
      roles: {
        required: required,
        customFarmerRoleMaxLength: customFarmerRoleMaxLength,
      },
      farms: {
        required: requiredIf(function () {return this.user.roles.length > 0 && !this.user.isOfTypeAdmin()}),
        farmerHasOneFarm: farmerHasOneFarm,
      },
      loginToken: {
        required: requiredIf(function () {return this.user.isOfTypeFarmer()}),
        userTokenFormat: function (value) {
          return /^[A-Za-z][.]*/.test(value);
        },
      },
      name: {
        required: required,
      },
      email: {
        required: required,
        email: email,
      },
      password: {
        required: requiredIf(function () {return !this.user.isLoaded()}),
        passwordMinLength: minLength(6)
      },
      copassword: {
        required: requiredIf(function () {return !this.user.isLoaded()}),
        sameAsPassword: sameAsPassswordValidator,
      }
    },
  },
  props: {
    user: {
      type: Object,
      required: true,
    }
  },
  data: function() {
    let that = this;
    return {
      roles: [],
      allowed_roles: [],
      farms: [],
      roleSelect2settings: {
        placeholder: this.$i18n.t('users.modal.placeholder.roles'),
        width: '100%',
        dropdownAutoWidth: true,
        theme: 'bootstrap',
        language: {
          noResults: () => that.$i18n.t("general.select.no_results"),
        },
      },
      farmSelect2settings: {
        placeholder: this.$i18n.t('users.modal.placeholder.farms'),
        maximumSelectionLength: this.user.isOfTypeFarmer() ? 1 : 0,
        width: '100%',
        dropdownAutoWidth: true,
        multiple: true,
        theme: 'bootstrap',
        language: {
          noResults: () => that.$i18n.t("general.select.no_results"),
          maximumSelected: () => that.$i18n.t("general.select.maxselection"),
        },
      },
    }
  },
  beforeMount() {
    this.getAvailableFarms();
    this.getRoles();
  },
  computed: {
    title: function () {
      if (this.user === null || !this.user.isLoaded()) {
        return this.$t("users.modal.create_title");
      }

      return this.$t("users.modal.edit_title");
    },
    isFormReady: function () {
      return this.user.isChanged() && !(this.v$.user.name.$error || this.v$.user.email.$error || this.v$.user.password.$error || this.v$.user.copassword.$error || this.v$.user.roles.$error || this.v$.user.farms.$error);
    },
    canDisableButtons: function () {
      return !this.user.isChanged() || !this.isFormReady || this.hasSameAccessAsLoggedInUser;
    },
    hasSameAccessAsLoggedInUser: function () {
      return !this.loggedInUser || (this.user.isLoaded() && this.user.getId() === this.loggedInUser.getId()) ? false : this.loggedInUser.hasAccess(this.user.getRoles());
    },
    canDisableFields: function () {
      return this.user.isLoaded() && (this.user.getId() === this.loggedInUser.getId() || this.hasSameAccessAsLoggedInUser);
    }
  },
  methods: {
    onLoadingComplete: function ()
    {
      setTimeout(function () {
        document.getElementsByTagName("body")[0].classList.add('modal-open');
      }, 2000);

      if (this.initialise) {
        this.user.setPassword("");
        this.user.setCoPassword("");
        this.user.reset();
      }
    },
    setIsActive: function (value)
    {
      this.user.setIsActive(value);
    },
    toggleIsDriver: function (value)
    {
      this.v$.user.roles.$reset();
      if (value) {
        this.user.addRole(User.ROLE.DRIVER)
      } else {
        this.user.removeRole(User.ROLE.DRIVER)
      }
      console.log(this.user.roles);
      this.v$.user.roles.$touch();
    },
    setName: function (user, value)
    {
      user.setName(value);
      this.v$.user.name.$touch();
    },
    setLoginToken: function (user, value)
    {
      user.setLoginToken(value);
      this.v$.user.loginToken.$touch();
    },
    setEmail: function (user, value)
    {
      user.setEmail(value);
      this.v$.user.email.$touch();
    },
    setRoles(selectedObject)
    {
      this.user.setRoles([selectedObject.id]);
      this.v$.user.roles.$reset();
      this.v$.user.roles.$touch();
      this.v$.user.farms.$reset();
      this.v$.user.farms.$touch();
    },
    setPassword: function (user, value)
    {
      user.setPassword(value);
      this.v$.user.password.$touch();
    },
    setCoPassword: function (user, value)
    {
      user.setCoPassword(value);
      this.v$.user.copassword.$touch();
    },
    setFarms(selectedObject)
    {
      if (!selectedObject.selected) {
        this.user.removeFarm(parseInt(selectedObject.id));
      } else {
        this.user.appendFarm(parseInt(selectedObject.id), selectedObject.text);
      }

      this.v$.user.farms.$touch();
    },
    updateUser: function ()
    {
      let that = this;
      let data = {
        'name': this.user.getName(),
        'email': this.user.getEmail(),
        'is_active': this.user.isActive(),
        'roles' : this.user.getRoles(),
        'farmers': this.user.getFarms().map(farm => farm.id),
      }

      this.v$.user.name.$touch();
      this.v$.user.email.$touch();
      this.v$.user.password.$touch();
      this.v$.user.copassword.$touch();
      this.v$.user.roles.$touch();
      this.v$.user.farms.$touch();
      this.v$.user.loginToken.$touch();

      if (!this.isFormReady) {
        return;
      }

      if (this.user.getPassword()) {
        data.password = this.user.getPassword();
      }

      if (this.user.isOfTypeFarmer()) {
        data.token = this.user.getLoginToken();
      }

      this.queueJob();
      this.resetNotifications();
      this.axios.put(this.$store.state.config.getUserUri(this.user.getId()), data).then(
          () => {
            that.user.setLoaded(true);
            that.addSuccessNotification("general.success");
          }
      ).catch(
          error => {
            console.warn(error);
            if (error.response && error.response.data.messages) {
              error.response.data.messages.forEach(message => that.addWarningNotification(message));
              that.scrollToNotificationBox();
            }
          }
      ).finally(function () {
        that.completeJob(true);
      });
    },
    createUser: function ()
    {
      let that = this;
      let data = {
        'name': this.user.getName(),
        'email': this.user.getEmail(),
        'is_active': this.user.isActive(),
        'roles' : this.user.getRoles(),
        'farmers': this.user.getFarms().map(farm => farm.id),
      }

      this.v$.user.name.$touch();
      this.v$.user.email.$touch();
      this.v$.user.password.$touch();
      this.v$.user.copassword.$touch();
      this.v$.user.roles.$touch();
      this.v$.user.farms.$touch();
      this.v$.user.loginToken.$touch();

      if (!this.isFormReady) {
        return;
      }

      if (this.user.getPassword()) {
        data.password = this.user.getPassword();
      }

      if (this.user.isOfTypeFarmer()) {
        data.token = this.user.getLoginToken();
      }

      this.queueJob();
      this.resetNotifications();
      this.axios.post(this.$store.state.config.getUsersUri(), data).then(
          response => {
            let content = response.data;
            that.user.setId(content.data.id);
            that.user.setLoaded(true);
            that.$emit('closemodal');
          }
      ).catch(
          error => {
            if (error.response && error.response.data.messages) {
              error.response.data.messages.forEach(message => that.addWarningNotification(message));
              that.scrollToNotificationBox();
            }
          }
      ).finally(function () {
        that.completeJob(true);
      });
    },
    getRoles: function ()
    {
      let that = this;

      this.queueJob();
      this.axios.get(this.$store.state.config.getRolesUri()).then(
          response => {
            let content = response.data;
            content.data.grants.forEach((role) => that.roles.push({
              id: role,
              text: that.$i18n.t('users.role', {role: that.$i18n.t(role)})
            }));
            content.data.allowed.forEach((role) => that.allowed_roles.push({
              id: role,
              text: that.$i18n.t('users.role', {role: that.$i18n.t(role)})
            }));
          }
      ).catch(
          error => {
            if (error.response && error.response.data.messages) {
              error.response.data.messages.forEach(message => that.addWarningNotification(message));
            }
          }
      ).finally(function () {
        that.completeJob();
      });
    },
    getAvailableFarms: function ()
    {
      let that = this;

      this.queueJob();
      this.axios.get(this.$store.state.config.getUserUri(this.loggedInUser.getId())).then(
          response => {
            let content = response.data;
            that.farms = content.data.farms.map( farm => {
              return {
                id: farm.id,
                text: farm.name
              }
            });
          }
      ).catch(
          error => {
            if (error.response && error.response.data.messages) {
              error.response.data.messages.forEach(message => that.addWarningNotification(message));
            }
          }
      ).finally(function () {
        that.completeJob();
      });
    }
  },
}
</script>

<style scoped>

</style>