import Changeable from "@/entities/Changeable";

export default class Moment extends Changeable
{
    static TYPE_DELIVERY = 'delivery';
    static TYPE_COLLECTING = 'collection';
    static TYPE_PACKING = 'packing';

    moment;

    dayOfWeek;
    time;
    exact;
    type;

    fixedDate;

    changedTime;

    constructor(moment = null) {
        super();
        if (null !== moment) {
            this.moment = moment;
            this.type = moment.type;
            this.dayOfWeek = ''+moment.day_of_week;
            this.time = moment.time;
            this.exact = moment.exact || null
            this.fixedDate = moment.exact || null;
        }
    }

    getId()
    {
        return this.moment.id;
    }

    getDayOfWeek()
    {
        return parseInt(this.dayOfWeek);
    }

    getTime()
    {
        return this.time;
    }

    getExact()
    {
        return this.exact;
    }

    getType()
    {
        return this.type;
    }

    isDelivery()
    {
        return this.getType() === Moment.TYPE_DELIVERY;
    }

    isCollecting()
    {
        return this.getType() === Moment.TYPE_COLLECTING;
    }

    isPacking()
    {
        return this.getType() === Moment.TYPE_PACKING;
    }

    setChangedTime(time)
    {
        this.changedTime = time;
        this.setChanged(true);
    }

    getChangedTime()
    {
        return this.changedTime ? this.changedTime : this.time;
    }

    getEstimatedDateTime()
    {
        let date = this.moment.estimated_date.split('-');
        let time = this.moment.time.split(':');
        return new Date(parseInt(date[2]), parseInt(date[1])-1, parseInt(date[0]), parseInt(time[0]), parseInt(time[1]));
    }

    getFixedDate()
    {
        if (this.fixedDate == null) {
            return null;
        }

        let dateTime = this.fixedDate.split(' ');
        let date = dateTime[0].split('-')
        let time = dateTime[1].split(':');

        return new Date(parseInt(date[0]), parseInt(date[1])-1, parseInt(date[2]), parseInt(time[0]), parseInt(time[1]), parseInt(time[2]));
    }

    isNextWeek()
    {
        return this.moment.is_next_week;
    }
}
