export default class Stock
{
    stock;
    constructor(stock = null) {
        this.stock = stock;
    }

    isAvailable()
    {
        return !!(this.stock && this.stock.is_available);
    }

    quantity()
    {
        return this.stock ? this.stock.qty : 0
    }
}
