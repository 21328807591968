<template>
  <transition name="fade">
    <Modal v-if="isLoading" :transparent-bg="true">
      <template v-slot:default>
        <Loading />
      </template>
    </Modal>
  </transition>
  <MainNavbar :has-side-menu-toggle="false" :has-notifications="false"/>
  <PageContent class="body verde mt-3" :centered="true" />
  <br class="clearfix mb-5" />
  <PageFooter />
</template>

<script>
import PageContent from "@/components/layout/PageContent";
import {LoadingModalMixin} from "@/mixins/ModalMixin";
import Modal from "@/components/elements/Modal";
import Loading from "@/components/elements/Loading";
import MainNavbar from "@/components/layout/MainNavbar";
import PageFooter from "@/components/layout/PageFooter";

export default {
  name: 'FrontofficeLayout',
  components: {PageFooter, MainNavbar, PageContent, Modal, Loading},
  mixins: [LoadingModalMixin],
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.body.verde  {
  font-size: 1rem;
}
::v-deep(.btn) {
  font-size: inherit;
}
::v-deep(.btn-labeled b) {
  padding: 0.7rem;
}
::v-deep(.progress) {
  font-size: inherit;
}
::v-deep(.form-control) {
  font-size: inherit;
}
::v-deep(.disabled) {
  width: 100%;
  background-color: #bbb;
  opacity:0.75;
}
</style>
