<template>
  <a href="#" class="navbar-nav-link dropdown-toggle caret-0" data-toggle="dropdown">
    <i class="icon-bell2"></i>
    <span class="d-md-none ml-2">{{ $t('notifications.list.title') }}</span>
  </a>
</template>

<script>
export default {
  name: "NotificationButton",
  props: {
    hasText: {
      type: Boolean,
      default: true,
    }
  }
}
</script>

<style scoped>

</style>