<template>
  <img class="logo icon-verde" v-if="isIco" src="../../assets/images/verde/logo-50x80.png">
  <img class="logo" v-else-if="isLight" src="../../assets/images/verde/logo-name-206x120-white.png" alt="VERDE LOGO">
  <img class="logo" v-else src="../../assets/images/verde/logo-name-206x120-dark.png" alt="VERDE LOGO">
</template>

<script>
export default {
  name: "Logo",
  props: {
    isLight: {
      type: Boolean,
      default: true,
    },
    isIco: {
      type: Boolean,
      default: false,
    }
  }
}
</script>

<style scoped>

</style>