export default class Token
{
    token;
    type;
    expireAt;
    roles;

    expireBufferInMs = 3*60*1000;

    constructor(token, expireAt = null, roles = []) {
        this.token = token.token;
        this.type = token.type;
        this.roles = roles;
        if (expireAt) {
            this.expireAt = expireAt;
        }
    }

    getToken()
    {
        return this.token;
    }

    getType()
    {
        return this.type;
    }

    getExpireAt()
    {
        return this.expireAt;
    }

    isExpired()
    {
        return this.expireAt && (new Date().getTime() + this.expireBufferInMs) > this.expireAt.getTime();
    }

    hasRole(role)
    {
        return this.roles.includes(role);
    }

    hasRoles(roles)
    {
        for (let role of roles) {
            if (!this.hasRole(role)) {
                continue;
            }
            return true;
        }

        return false;
    }
}