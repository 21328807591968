<template>
  <div v-bind:class="alertClass" class="alert">
    <button v-if="dismissible" type="button" class="close" data-dismiss="alert"><span>&times;</span></button>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Alert",
  props: {
    type: {
      type: String,
    },
    styled: {
      type: Boolean,
      default: true,
    },
    dismissible: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    alertClass: function () {
      return "alert-" + this.type + (this.dismissible ? " alert-dismissible" : "") + (this.styled ? " alert-styled-left" : " border-0");
    }
  }
}
</script>

<style scoped>

</style>