<template>
  <FrontofficeLayout v-if="isFrontofficeLayout"/>
  <BackofficeSimpleLayout v-else-if="isBackofficeSimpleLayout" />
  <BackofficeLayout v-else />
</template>

<script>
import BackofficeLayout from "@/components/BackofficeLayout";
import FrontofficeLayout from "@/components/FrontofficeLayout";
import BackofficeSimpleLayout from "@/components/BackofficeSimpleLayout";
import Configuration from "@/entities/Configuration";
import {AuthorizationMixin, NotificationsMixin} from "@/mixins/GeneralMixin";
import Cycle from "@/entities/Cycle";
import {LoadingModalMixin} from "@/mixins/ModalMixin";

export default {
  name: 'App',
  inheritAttrs: false,
  mixins: [AuthorizationMixin, NotificationsMixin, LoadingModalMixin],
  components: {
    BackofficeSimpleLayout,
    BackofficeLayout,
    FrontofficeLayout,
  },
  watch: {
    loggedInUser: {
      handler: async function (value) {
        if (value !== null) {
          await this.initActiveCycle();
        }
      },
    }
  },
  beforeCreate() {
    let that = this;
    this.axios.interceptors.request.use(config => {
      that.getAuthFromToken();
      if (that.authorization) {
        config.headers.common['Authorization'] = that.authorization;
      }
      return config;
    });

    this.axios.interceptors.response.use(undefined, function (error) {
      let statusCode = error.response ? error.response.status : null;
      switch (statusCode) {
        case 401:
          that.$nextTick(function () {
            let props = this.$router.resolve({
              name: 'general.breadcrumb.users.login',
              query: {expired: 'yes'},
            });
            window.location = props.href;
          });
          return Promise.reject(error);
        case 403:
          if (that.$store.state.skipResponseCodeCheck !== statusCode) {
            that.addGlobalWarningNotification('general.errors.forbidden.object');
            that.$nextTick(function () {
              let props = this.$router.resolve({
                name: 'general.errors.forbidden.title',
                query: {code: "49631222"},
              });
              window.location = props.href;
            });
          } else {
            that.$store.commit("enableResponseCodeCheck");
          }
          return Promise.reject(error);
        case 404:
          if (that.$store.state.skipResponseCodeCheck !== statusCode) {
            that.addGlobalWarningNotification('general.errors.notfound.object');
            that.$nextTick(function () {
              let props = this.$router.resolve({
                name: 'general.errors.notfound.title',
              });
              window.location = props.href;
            });
          } else {
            that.$store.commit("enableResponseCodeCheck");
          }
          return Promise.reject(error);
        case 408:
        case 500:
          if (error.response && error.response.data && error.response.data.message) {
            that.addGlobalErrorNotification(error.response.data.message);
          }
          else that.addGlobalErrorNotification('general.errors.fatal');
          return Promise.reject(error);
        default:
          return Promise.reject(error);
      }
    });
  },
  beforeMount() {
    this.getAuthFromToken();
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onScreenResize);
    });
    this.$nextTick(() => {
      window.addEventListener('init_cycle', this.initActiveCycle);
    });

    let that = this;

    this.$router.beforeEach((to, from, next) => {
      const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
      const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
      const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
      if(nearestWithTitle && that.$i18n) {
        document.title = 'Verde: ' + that.$i18n.t(nearestWithTitle.meta.title);
      } else if(previousNearestWithMeta) {
        document.title = 'Verde: ' + that.$i18n.t(previousNearestWithMeta.meta.title);
      }

      Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));
      if(!nearestWithMeta) return next();

      nearestWithMeta.meta.metaTags.map(tagDef => {
        const tag = document.createElement('meta');

        Object.keys(tagDef).forEach(key => {
          tag.setAttribute(key, tagDef[key]);
        });
        tag.setAttribute('data-vue-router-controlled', '');

        return tag;
      }).forEach(tag => document.head.appendChild(tag));

      next();
    });

    this.$router.beforeEach((to) => {
      that.$store.commit('futureRedirect', to);
    });

    this.$router.beforeEach((to) => {
      if (
          typeof to.meta.resetNotifications !== 'undefined' &&
          false === to.meta.resetNotifications
      ) {
        return;
      }
      that.resetGlobalNotifications();
    });

    this.$router.beforeEach(async (to, from, next) => {
      if (!to.meta || !to.meta.authentication || !to.meta.authentication.enabled) {
        return next();
      }

      if (that.loggedInToken && that.loggedInToken.isExpired()) {
        await that.renewToken();
      }

      if (!that.loggedInToken || that.loggedInToken.isExpired()) {
        that.$nextTick(function () {
          that.$store.commit('logout');

          that.$store.commit('afterLoginRedirectTo', that.$store.state.redirectTo);
          if (to.meta.login_token === true) {
            return next({name: 'general.breadcrumb.users.login', query: {token: "yes"}});
          }
          return next({name: 'general.breadcrumb.users.login', query: {expired: "yes"}});
        })
        return;
      }

      if (!that.loggedInUser.hasAccess(to.meta.authentication.roles || [])) {
        await that.renewToken();
        if (!that.loggedInUser.hasAccess(to.meta.authentication.roles || [])) {
          return next({name: 'general.errors.forbidden.title', query: {code: "49631197"}});
        }
      }

      return next();
    });
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onScreenResize);
    window.removeEventListener('init_cycle', this.initActiveCycle);
  },
  computed: {
    isFrontofficeLayout: function () {
      return this.$route.meta.layout == Configuration.LAYOUT_FRONTOFFICE;
    },
    isBackofficeSimpleLayout: function () {
      return this.$route.meta.layout == Configuration.LAYOUT_BACKOFFICE_SIMPLE;
    }
  },
  methods: {
    onScreenResize: function () {
      this.$store.commit('resizeScreen');
    },
    initActiveCycle: function () {
      if (!this.isLoggedInUserAndValid) {
        return;
      }

      let that = this;
      this.$store.commit('setActiveCycle', null);

      return this.axios.get(this.$store.state.config.getActiveCycleUri()).then(
          response => {
            let content = response.data;
            let cycle = new Cycle(content.data);
            cycle.setLoaded(true);
            that.$store.commit('setActiveCycle', cycle);
            Promise.resolve(true);
          }
      ).catch(
          error => {
            console.warn(error);
          }
      );
    },
  },
}
</script>

<style>
.clickable {
  cursor: pointer;
}

html {
  scroll-behavior: smooth !important;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.2s
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0
}
.h2.verde {
  font-size: 1.4375rem !important;
}

.verde .badge {
  font-size: inherit;
}

.dark-overlay {
  background-color: rgba(0,0,0,0.45);
}

.verde .v3dp__datepicker .v3dp__popout {
  position: fixed !important;
}

.complete-bg {
  background-color: rgba(76,175,80,0.1);
}

.font-size-larger {
  font-size: 1.2em;
}
.navbar-dark,
.sidebar-dark,
.sidebar-dark .sidebar-mobile-toggler:not([class*=bg-]) {
  background-color: #17393a;
}

.bg-verde {
  background-color: #17393a !important;
}

.text-verde {
  color: #17393a !important;;
}

.bg-success.verde {
  background-color: #17393a !important;
}
.error .file-upload-container,
textarea.error,
.v3dp__datepicker .error,
.form-control.error,
.error .select2-selection {
  border: 1px solid #F44336 !important;
}

.select2-selection.select2-selection--single {
  height: 36px !important;
}

.select2-container .select2-selection {
  max-height: 108px;
  overflow: auto;
}

.table td, .table th {
  padding: .50rem 0.75rem;
}

button.verde-link {
  font-size: 1.0625rem;
  background: none!important;
  border: none;
  padding: 0!important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}

.verde.no-padding .msg, .verde.no-padding .card-body {
  padding: 0;
}

.verde.small-padding .msg {
  padding: 2px;
}

.verde.small-padding .card-body {
  padding: 5px;
}

.card-header-pb-0 .card-header {
  padding-bottom: 0 !important;
}
.card-title-mb-0 .card-title {
  margin-bottom: 0 !important;
}
.card-msg-pt-0 .msg {
  padding-top: 0 !important;
}

.with-sticky-footer-buttons {
  padding-bottom: 80px;
}

.v3dp__datepicker .v3dp__input_wrapper {
  position: relative;
}
.v3dp__datepicker .v3dp__clearable {
  position: absolute;
  right: 0px;
  left: initial;
  top: 0px;
  bottom: 0px;
  margin: auto;
  height: 1.5em;
  line-height: 1.5em;
  width: 2em;
  text-align: center;
}
</style>
