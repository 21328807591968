import Address from "@/entities/Address";
import Loadable from "@/entities/Loadable";
import Changeable from "@/entities/Changeable";
import Cycle from "@/entities/Cycle";
import LoadableCollection from "@/entities/LoadableCollection";
import Company from "@/entities/Company";

export default class Farmer extends Loadable
{
    farmer;
    address;
    company;
    product_categories;
    changeable;
    cycles;

    constructor(farmer = null)
    {
        super();
        this.changeable = new Changeable();
        this.cycles = new LoadableCollection();

        if (null === farmer) {
            this.farmer = {};
            this.address = new Address();
            this.company = new Company();
            this.product_categories = [];
            return;
        }

        this.farmer = farmer;
        if (farmer.cycles) {
            farmer.cycles.forEach( cycle => this.cycles.push(new Cycle(cycle)));
            this.cycles.loaded();
        }

        if (typeof farmer.addresses[0] !== 'undefined') {
            this.address = new Address(farmer.addresses[0]);
        }
        if (typeof farmer.company_name !== 'undefined') {
            this.company = new Company(farmer.company_name);
            this.company.setCompanyAddress(farmer.company_address);
            this.company.setVat(farmer.vat);
            this.company.setCui(farmer.cui);
            if (farmer.legal) {
                this.company.setLegalType(farmer.legal.name);
            }
        }


        if (typeof this.farmer.product_categories !== 'undefined') {
            this.product_categories = this.farmer.product_categories.map((category) => {
                return {id: category.category_id, name: category.name};
            });
        }
        else {
            this.product_categories = [];
        }
        this.setLoaded(true);
    }

    setId(id)
    {
        this.farmer.id = id;
    }

    getId()
    {
        return this.farmer.id;
    }

    getName()
    {
        return this.farmer.name;
    }

    getLogo() {
        return this.farmer.logo;
    }

    getBrandName()
    {
        return this.farmer.brand_name;
    }

    getTelephone()
    {
        return this.farmer.telephone;
    }

    getUpdatedAt()
    {
        return this.farmer.updated_at;
    }

    isActive()
    {
        return !!this.farmer.is_active;
    }

    showCommissionDetails()
    {
        return !!this.farmer.show_commission_details;
    }

    sellsImportProducts()
    {
        return !!this.farmer.sells_import_products;
    }

    getTotalProducts()
    {
        return this.farmer.total_products;
    }

    getAvailableProducts()
    {
        return this.farmer.available_products;
    }

    getEnGrossProducts()
    {
        return this.farmer.en_gross_products;
    }

    getEmail()
    {
        return this.farmer.email;
    }

    getAddress()
    {
        return this.address;
    }

    getCompany() {
        return this.company;
    }

    getProductCategories()
    {
        return this.product_categories;
    }

    getProductCategoriesLabels()
    {
        return this.product_categories.map(category => category.name).join(', ');
    }

    removeProductCategory(categoryId)
    {
        this.product_categories = this.product_categories.filter(farm => farm.id !== categoryId);
        this.setChanged(true);
    }

    appendProductCategory(categoryId, categoryName)
    {
        this.product_categories.push({id: categoryId, name: categoryName});
        this.setChanged(true);
    }

    setChanged(changed)
    {
        this.changeable.setChanged(changed);
    }

    isChanged()
    {
        return this.changeable.isChanged();
    }

    setName(name)
    {
        this.farmer.name = name;
        this.setChanged(true);
    }

    setBrandName(name)
    {
        this.farmer.brand_name = name;
        this.setChanged(true);
    }

    setLogo(logo) {
        this.farmer.logo = logo;
        this.setChanged(true);
    }

    setTelephone(telephone)
    {
        this.farmer.telephone = telephone;
        this.setChanged(true);
    }

    setEmail(email)
    {
        this.farmer.email = email;
        this.setChanged(true);
    }

    setIsActive(isActive)
    {
        this.farmer.is_active = isActive;
        this.setChanged(true);
    }

    setShowCommissionDetails(showCommissionDetails)
    {
        this.farmer.show_commission_details = showCommissionDetails;
        this.setChanged(true);
    }

    setSellsImportProducts(sellsImportProducts)
    {
        this.farmer.sells_import_products = sellsImportProducts;
        this.setChanged(true);
    }

    isDeleted()
    {
        return !!this.farmer.deleted_at;
    }

    canNotify()
    {
        return !!this.farmer.can_notify;
    }

    setCanNotify(canNotify)
    {
        this.farmer.can_notify = !!canNotify;
    }

    getCycles()
    {
        return this.cycles;
    }

    getSendToCollectionWeekDays()
    {
        return this.cycles
            .getItems()
            .map(cycle => cycle.getSendToCollectionBatch().getDayOfWeek())
            .filter(day => !isNaN(day))
            .sort();
    }

    getSendToCollectionTime()
    {
        return this.cycles.getItems().map(cycle => cycle.getSendToCollectionBatch().getTime()).filter(time => null !== time).shift();
    }

    getCountry() {
        return this.farmer.country
    }

    setCountry(country) {
        this.farmer.country = country;
        this.setChanged(true);
    }

    getCounty() {
        return this.farmer.county
    }

    setCounty(county) {
        this.farmer.county = county;
        this.setChanged(true);
    }


    getDescription() {
        return this.farmer.description
    }

    setDescription(description) {
        this.farmer.description = description;
        this.setChanged(true);
    }

    getProductsDescription() {
        return this.farmer.products_description
    }

    setProductsDescription(productsDescription) {
        this.farmer.products_description = productsDescription;
        this.setChanged(true);
    }

    getProductsAvailability() {
        if (this.farmer.products_availability)
            return this.farmer.products_availability
        return {
            1: false,
            2: false,
            3: false,
            4: false,
            5: false,
            6: false,
            7: false,
            8: false,
            9: false,
            10: false,
            11: false,
            12: false
        }
    }

    setProductsAvailability(productsAvailability) {
        this.farmer.products_availability = productsAvailability;
        this.setChanged(true);
    }

    getProductionCapacity() {
        return this.farmer.production_capacity
    }

    setProductionCapacity(productionCapacity) {
        this.farmer.production_capacity = productionCapacity;
        this.setChanged(true);
    }

    getCertifications() {
        return this.farmer.certifications
    }

    setCertifications(certifications) {
        this.farmer.certifications = certifications;
        this.setChanged(true);
    }

    getIsBio() {
        return !!this.farmer.is_bio
    }

    setIsBio(isBio) {
        this.farmer.is_bio = isBio;
        this.setChanged(true);
    }

    getIsMountain() {
        return !!this.farmer.is_mountain
    }

    setIsMountain(isMountain) {
        this.farmer.is_mountain = isMountain;
        this.setChanged(true);
    }

    getDeliveryArea() {
        return this.farmer.delivery_area;
    }

    setDeliveryArea(deliveryArea) {
        this.farmer.delivery_area = deliveryArea;
        this.setChanged(true);
    }

    getMinOrderLocal() {
        return this.farmer.min_order_local;
    }

    setMinOrderLocal(minOrderLocal) {
        this.farmer.min_order_local = minOrderLocal;
        this.setChanged(true);
    }

    getMinOrderNational() {
        return this.farmer.min_order_national;
    }

    setMinOrderNational(minOrderNational) {
        this.farmer.min_order_national = minOrderNational;
        this.setChanged(true);
    }

    getMinOrderEU() {
        return this.farmer.min_order_eu;
    }

    setMinOrderEU(minOrderEU) {
        this.farmer.min_order_eu = minOrderEU;
        this.setChanged(true);
    }

}
