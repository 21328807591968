<template>
<div class="row">
  <div class="col-md-12 text-center">
    <Spinner :loading="true"/>
    <br v-if="hasText" class="clearfix" />
    <h2 v-if="hasText">{{ $t('general.loading') }} ...</h2>
  </div>
</div>
</template>

<script>
import Spinner from "@/components/elements/Spinner";
export default {
  name: "Loading",
  components: {Spinner},
  props: {
    hasText: {
      type: Boolean,
      default: true,
    }
  }
}
</script>

<style scoped>

</style>
