<template>
  <transition name="fade">
    <Modal v-if="isLoading" :transparent-bg="true" style="z-index: 99999999">
      <template v-slot:default>
        <Loading />
      </template>
    </Modal>
  </transition>
  <MainNavbar />
  <PageHeader />
  <PageContent />
  <br class="clearfix mb-5" />
  <PageFooter />
</template>

<script>
import MainNavbar from "@/components/layout/MainNavbar";
import PageHeader from "@/components/layout/PageHeader";
import PageFooter from "@/components/layout/PageFooter";
import PageContent from "@/components/layout/PageContent";
import Modal from "@/components/elements/Modal";
import Loading from "@/components/elements/Loading";
import {LoadingModalMixin} from "@/mixins/ModalMixin";

export default {
  name: 'BackofficeLayout',
  mixins: [LoadingModalMixin],
  components: {Loading, Modal, PageContent, PageFooter, PageHeader, MainNavbar},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
::v-deep(.no-mobile-zoom) {
  font-size: 1rem;
}
</style>
