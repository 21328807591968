import {createWebHistory, createRouter} from "vue-router";

import Configuration from "@/entities/Configuration";

const Dashboard = () => import("@/components/views/backoffice/Dashboard");
const OrderList = () => import("@/components/views/backoffice/OrderList");
const OrderUpload = () => import("@/components/views/backoffice/OrderUpload");
const UserList = () => import("@/components/views/backoffice/UserList");
const FarmerList = () => import("@/components/views/backoffice/FarmerList");
const FarmerEdit = () => import("@/components/views/backoffice/FarmerEdit");
const BatchList = () => import("@/components/views/backoffice/BatchList");
const NotificationList = () => import("@/components/views/backoffice/NotificationList");
const CycleList = () => import("@/components/views/backoffice/CycleList");
const OrderEdit = () => import("@/components/views/backoffice/OrderEdit");
const BatchGenerate = () => import("@/components/views/backoffice/BatchGenerate");
const BatchEdit = () => import("@/components/views/backoffice/BatchEdit");
const SideBar = () => import("@/components/layout/SideBar");
const FarmerBatchList = () => import("@/components/views/frontoffice/FarmerBatchList");
const FarmerPackList = () => import("@/components/views/frontoffice/FarmerPackList");
const UserLogin = () => import("@/components/views/backoffice/UserLogin");
const UserForgotPassword = () => import("@/components/views/backoffice/UserForgotPassword");
const DeliveryNoteList = () => import("@/components/views/backoffice/DeliveryNoteList");
const DriverCollect = () => import("@/components/views/frontoffice/DriverCollect");
const ErrorForbidden = () => import("@/components/views/error/ErrorForbidden");
const ErrorNotFound = () => import("@/components/views/error/ErrorNotFound");
const StockBatchList = () => import("@/components/views/backoffice/StockBatchList");
const StockBatchEdit = () => import("@/components/views/backoffice/StockBatchEdit");
const OfferStockBatch = () => import("@/components/views/frontoffice/farmer/OfferStockBatch");
const OfferEnGross = () => import("@/components/views/frontoffice/farmer/OfferEnGross");
const OfferDashboard = () => import("@/components/views/frontoffice/farmer/OfferDashboard");

const routes = [
    { path: '/', redirect: '/admin' },
    {
        path: "/errors/forbidden/",
        name: "general.errors.forbidden.title",
        meta: {
            title: "general.errors.forbidden.title",
            metaTags: [{
                name: '',
                description: '',
            }],
            layout: Configuration.LAYOUT_BACKOFFICE_SIMPLE,
            category: "general.errors",
            breadcrumb: [
                {name: "general.errors.forbidden.title", link: ""},
            ]
        },
        components: {
            default: ErrorForbidden,
        },
    },
    {
        path: "/errors/not-found/",
        name: "general.errors.notfound.title",
        meta: {
            title: "general.errors.notfound.title",
            metaTags: [{
                name: '',
                description: '',
            }],
            layout: Configuration.LAYOUT_BACKOFFICE_SIMPLE,
            category: "general.errors",
            breadcrumb: [
                {name: "general.errors.notfound.title", link: ""},
            ]
        },
        components: {
            default: ErrorNotFound,
        },
    },
    {
        path: "/farmers/:id/batches/:batchId/",
        name: "frontoffice.breadcrumb.farmers.batch",
        meta: {
            title: "frontoffice.breadcrumb.farmers.batch",
            authentication: {
                enabled: true,
                roles: ['super-admin', 'admin', 'farmer'],
            },
            metaTags: [{
                name: '',
                description: '',
            }],
            layout: Configuration.LAYOUT_FRONTOFFICE,
            login_token: true,
            category: "frontoffice.breadcrumb.farmers",
            breadcrumb: [
                {name: "frontoffice.breadcrumb.farmers.batch", link: ""},
            ]
        },
        components: {
            default: FarmerBatchList,
        },
    },
    {
        path: "/delivery/:noteId/collect/",
        name: "frontoffice.breadcrumb.drivers.collect",
        meta: {
            title: "frontoffice.breadcrumb.drivers.collect",
            authentication: {
                enabled: true,
                roles: [/*'super-admin', 'admin', 'driver'*/],
            },
            layout: Configuration.LAYOUT_FRONTOFFICE,
            category: "frontoffice.breadcrumb.drivers",
            breadcrumb: [
                {name: "frontoffice.breadcrumb.drivers.collect", link: ""},
            ]
        },
        components: {
            default: DriverCollect,
        },
    },
    {
        path: "/delivery/:noteId/deliver/",
        name: "frontoffice.breadcrumb.drivers.deliver",
        meta: {
            title: "frontoffice.breadcrumb.drivers.deliver",
            authentication: {
                enabled: true,
                roles: [/*'super-admin', 'admin', 'driver'*/],
            },
            layout: Configuration.LAYOUT_FRONTOFFICE,
            category: "frontoffice.breadcrumb.drivers",
            breadcrumb: [
                {name: "frontoffice.breadcrumb.drivers.deliver", link: ""},
            ]
        },
        components: {
            default: DriverCollect,
        },
    },
    {
        path: "/farmers/:id/offers/:batchId/",
        name: "frontoffice.breadcrumb.farmers.offers.dashboard",
        meta: {
            title: "frontoffice.breadcrumb.farmers.offers.dashboard",
            authentication: {
                enabled: true,
                roles: ['super-admin', 'admin', 'farmer'],
            },
            layout: Configuration.LAYOUT_FRONTOFFICE,
            category: "frontoffice.breadcrumb.farmers",
            login_token: true,
            breadcrumb: [
                {name: "frontoffice.breadcrumb.farmers.offers.dashboard", link: ""},
            ]
        },
        components: {
            default: OfferDashboard,
        },
        children: [
            {
                path: "/farmers/:id/offers/:batchId/en-gross/",
                name: "frontoffice.breadcrumb.farmers.offers.en-gross",
                meta: {
                    title: "frontoffice.breadcrumb.farmers.offers.en-gross",
                    authentication: {
                        enabled: true,
                        roles: ['super-admin', 'admin', 'farmer'],
                    },
                    layout: Configuration.LAYOUT_FRONTOFFICE,
                    category: "frontoffice.breadcrumb.farmers",
                    breadcrumb: [
                        {name: "frontoffice.breadcrumb.farmers.offers.dashboard", link: "frontoffice.breadcrumb.farmers.offers.dashboard"},
                        {name: "frontoffice.breadcrumb.farmers.offers.en-gross", link: ""},
                    ]
                },
                components: {
                    default: OfferEnGross,
                },
            },
            {
                path: "/farmers/:id/offers/:batchId/stocks-batches/",
                name: "frontoffice.breadcrumb.farmers.offers.stock-batch",
                meta: {
                    title: "frontoffice.breadcrumb.farmers.offers.stock-batch",
                    authentication: {
                        enabled: true,
                        roles: ['super-admin', 'admin', 'farmer'],
                    },
                    layout: Configuration.LAYOUT_FRONTOFFICE,
                    category: "frontoffice.breadcrumb.farmers",
                    breadcrumb: [
                        {name: "frontoffice.breadcrumb.farmers.offers.dashboard", link: "frontoffice.breadcrumb.farmers.offers.dashboard"},
                        {name: "frontoffice.breadcrumb.farmers.offers.stock-batch", link: ""},
                    ]
                },
                components: {
                    default: OfferStockBatch,
                },
            },
        ]
    },
    {
        path: "/farmers/:id/batches/:batchId/prepack/",
        name: "frontoffice.breadcrumb.farmers.prepack",
        meta: {
            title: "frontoffice.breadcrumb.farmers.prepack",
            authentication: {
                enabled: true,
                roles: ['super-admin', 'admin', 'farmer'],
            },
            layout: Configuration.LAYOUT_FRONTOFFICE,
            category: "frontoffice.breadcrumb.farmers",
            login_token: true,
            breadcrumb: [
                {name: "frontoffice.breadcrumb.farmers.batch", link: "frontoffice.breadcrumb.farmers.batch"},
                {name: "frontoffice.breadcrumb.farmers.prepack", link: ""},
            ]
        },
        components: {
            default: FarmerBatchList,
        },
        children: [
            {
                path: "/farmers/:id/batches/:batchId/pack/",
                name: "frontoffice.breadcrumb.farmers.pack",
                meta: {
                    title: "frontoffice.breadcrumb.farmers.pack",
                    authentication: {
                        enabled: true,
                        roles: ['super-admin', 'admin', 'farmer'],
                    },
                    layout: Configuration.LAYOUT_FRONTOFFICE,
                    category: "frontoffice.breadcrumb.farmers",
                    breadcrumb: [
                        {name: "frontoffice.breadcrumb.farmers.batch", link: "frontoffice.breadcrumb.farmers.batch"},
                        {name: "frontoffice.breadcrumb.farmers.prepack", link: "frontoffice.breadcrumb.farmers.prepack"},
                        {name: "frontoffice.breadcrumb.farmers.pack", link: ""},
                    ]
                },
                components: {
                    default: FarmerPackList,
                },
            },
        ]
    },
    {
        path: "/admin/",
        name: "general.breadcrumb.dashboard",
        meta: {
            title: "general.breadcrumb.dashboard",
            authentication: {
                enabled: true,
                roles: ['super-admin', 'admin', 'farmer', 'user', 'driver'],
            },
            category: "general.breadcrumb.administration",
            breadcrumb: [
                {name: "general.breadcrumb.dashboard", link: ""},
            ]
        },
        components: {
            default: Dashboard,
            sidebar: SideBar,
        },
    },
    {
        path: "/admin/login",
        name: "general.breadcrumb.users.login",
        meta: {
            title: "general.breadcrumb.users.login",
            layout: Configuration.LAYOUT_FRONTOFFICE,
            category: "general.breadcrumb.administration",
            resetNotifications: false,
            breadcrumb: [
                {name: "general.breadcrumb.users.login", link: ""},
            ]
        },
        components: {
            default: UserLogin,
        },
        children: [
            {
                path: "/admin/forgot-password",
                name: "general.breadcrumb.users.forgot",
                meta: {
                    title: "general.breadcrumb.users.forgot",
                    layout: Configuration.LAYOUT_FRONTOFFICE,
                    category: "general.breadcrumb.administration",
                    breadcrumb: [
                        {name: "general.breadcrumb.users.forgot", link: ""},
                    ]
                },
                components: {
                    default: UserForgotPassword,
                },
            },
        ]
    },
    {
        path: "/admin/users/",
        name: "general.breadcrumb.users.list",
        meta: {
            title: "general.breadcrumb.users.list",
            authentication: {
                enabled: true,
                roles: ['super-admin', 'admin'],
            },
            category: "general.breadcrumb.administration",
            breadcrumb: [
                {name: "general.breadcrumb.users.list", link: ""},
            ]
        },
        components: {
            default: UserList,
            sidebar: SideBar,
        },
    },
    {
        path: "/admin/farmers/",
        name: "general.breadcrumb.farmers.list",
        meta: {
            title: "general.breadcrumb.farmers.list",
            authentication: {
                enabled: true,
                roles: ['super-admin', 'admin', 'user'],
            },
            category: "general.breadcrumb.administration",
            breadcrumb: [
                {name: "general.breadcrumb.farmers.list", link: ""},
            ]
        },
        components: {
            default: FarmerList,
            sidebar: SideBar,
        },
        children: [
            {
                path: "/admin/farmers/:id/edit/",
                name: "general.breadcrumb.farmers.edit",
                meta: {
                    title: "general.breadcrumb.farmers.edit",
                    authentication: {
                        enabled: true,
                        roles: ['super-admin', 'admin', 'user'],
                    },
                    category: "general.breadcrumb.administration",
                    breadcrumb: [
                        {name: "general.breadcrumb.farmers.list", link: "general.breadcrumb.farmers.list"},
                        {name: "general.breadcrumb.farmers.edit", link: ""},
                    ]
                },
                components: {
                    default: FarmerEdit,
                    sidebar: SideBar,
                },
            },
        ]
    },
    {
        path: "/admin/orders/",
        name: "general.breadcrumb.orders.list",
        meta: {
            title: "general.breadcrumb.orders.list",
            authentication: {
                enabled: true,
                roles: ['super-admin', 'admin', 'user'],
            },
            category: "general.breadcrumb.administration",
            breadcrumb: [
                {name: "general.breadcrumb.orders.list", link: ""},
            ]
        },
        components: {
            default: OrderList,
            sidebar: SideBar,
        },
        children: [
            {
                path: "/admin/orders/upload",
                name: "general.breadcrumb.orders.upload",
                meta: {
                    title: "general.breadcrumb.orders.upload",
                    authentication: {
                        enabled: true,
                        roles: ['super-admin', 'admin', 'user'],
                    },
                    category: "general.breadcrumb.administration",
                    breadcrumb: [
                        {name: "general.breadcrumb.orders.list", link: "general.breadcrumb.orders.list"},
                        {name: "general.breadcrumb.orders.upload", link: ""}
                    ],
                },
                components: {
                    default: OrderUpload,
                    sidebar: SideBar,
                },
            },
            {
                path: "/admin/orders/:orderId/edit",
                name: "general.breadcrumb.orders.edit",
                meta: {
                    title: "general.breadcrumb.orders.edit",
                    authentication: {
                        enabled: true,
                        roles: ['super-admin', 'admin', 'user'],
                    },
                    category: "general.breadcrumb.administration",
                    breadcrumb: [
                        {name: "general.breadcrumb.orders.list", link: "general.breadcrumb.orders.list"},
                        {name: "general.breadcrumb.orders.edit", link: ""}
                    ],
                },
                components: {
                    default: OrderEdit,
                    sidebar: SideBar,
                },
            },
        ],
    },
    {
        path: "/admin/batches/",
        name: "general.breadcrumb.batches.list",
        meta: {
            title: "general.breadcrumb.batches.list",
            authentication: {
                enabled: true,
                roles: ['super-admin', 'admin', 'user', 'farmer'],
            },
            category: "general.breadcrumb.administration",
            breadcrumb: [
                {name: "general.breadcrumb.batches.list", link: ""},
            ]
        },
        components: {
            default: BatchList,
            sidebar: SideBar,
        },
        children: [
            {
                path: "/admin/batches/generate/",
                name: "general.breadcrumb.batches.generate",
                meta: {
                    title: "general.breadcrumb.batches.generate",
                    authentication: {
                        enabled: true,
                        roles: ['super-admin', 'admin', 'user'],
                    },
                    category: "general.breadcrumb.administration",
                    breadcrumb: [
                        {name: "general.breadcrumb.batches.list", link: "general.breadcrumb.batches.list"},
                        {name: "general.breadcrumb.batches.generate", link: ""},
                    ]
                },
                components: {
                    default: BatchGenerate,
                    sidebar: SideBar,
                },
            },
            {
                path: "/admin/batches/:batchId/edit",
                name: "general.breadcrumb.batches.edit",
                meta: {
                    title: "general.breadcrumb.batches.edit",
                    authentication: {
                        enabled: true,
                        roles: ['super-admin', 'admin', 'user', 'farmer'],
                    },
                    category: "general.breadcrumb.administration",
                    breadcrumb: [
                        {name: "general.breadcrumb.batches.list", link: "general.breadcrumb.batches.list"},
                        {name: "general.breadcrumb.batches.edit", link: ""}
                    ],
                },
                components: {
                    default: BatchEdit,
                    sidebar: SideBar,
                },
            },
        ],
    },
    {
        path: "/admin/delivery-notes/",
        name: "general.breadcrumb.deliverynotes.list",
        meta: {
            title: "general.breadcrumb.deliverynotes.list",
            authentication: {
                enabled: true,
                roles: ['super-admin', 'admin', 'user', 'driver'],
            },
            category: "general.breadcrumb.administration",
            breadcrumb: [
                {name: "general.breadcrumb.deliverynotes.list", link: ""},
            ]
        },
        components: {
            default: DeliveryNoteList,
            sidebar: SideBar,
        },
    },
    {
        path: "/admin/notifications/",
        name: "general.breadcrumb.notifications.list",
        meta: {
            title: "general.breadcrumb.notifications.list",
            authentication: {
                enabled: true,
                roles: ['super-admin', 'admin', 'user', 'farmer'],
            },
            category: "general.breadcrumb.administration",
            breadcrumb: [
                {name: "general.breadcrumb.notifications.list", link: ""},
            ]
        },
        components: {
            default: NotificationList,
            sidebar: SideBar,
        },
    },
    {
        path: "/admin/cycles/",
        name: "general.breadcrumb.cycles.list",
        meta: {
            title: "general.breadcrumb.cycles.list",
            authentication: {
                enabled: true,
                roles: ['super-admin', 'admin'],
            },
            category: "general.breadcrumb.administration",
            breadcrumb: [
                {name: "general.breadcrumb.cycles.list", link: ""},
            ]
        },
        components: {
            default: CycleList,
            sidebar: SideBar,
        },
    },
    {
        path: "/admin/stocks/batches/",
        name: "general.breadcrumb.stocks.batches.list",
        meta: {
            title: "general.breadcrumb.stocks.batches.list",
            authentication: {
                enabled: true,
                roles: ['super-admin', 'admin', 'user', 'farmer'],
            },
            category: "general.breadcrumb.administration",
            breadcrumb: [
                {name: "general.breadcrumb.stocks.batches.list", link: ""},
            ]
        },
        components: {
            default: StockBatchList,
            sidebar: SideBar,
        },
        children: [
            {
                path: "/admin/stocks/batches/:batchId/edit",
                name: "general.breadcrumb.stocks.batches.edit",
                meta: {
                    title: "general.breadcrumb.stocks.batches.edit",
                    authentication: {
                        enabled: true,
                        roles: ['super-admin', 'admin', 'user', 'farmer'],
                    },
                    category: "general.breadcrumb.administration",
                    breadcrumb: [
                        {name: "general.breadcrumb.stocks.batches.list", link: "general.breadcrumb.stocks.batches.list"},
                        {name: "general.breadcrumb.stocks.batches.edit", link: ""}
                    ],
                },
                components: {
                    default: StockBatchEdit,
                    sidebar: SideBar,
                },
            },
        ],
    },
    {
        path: '/farmers/:id/stock-batches/:batchId/',
        redirect: () => {
            return {name: "frontoffice.breadcrumb.farmers.offers.dashboard"};
        },
    },
    {
        path: '/:pathMatch(.*)',
        meta: {
            title: "general.errors.notfound.title",
            metaTags: [{
                name: '',
                description: '',
            }],
            layout: Configuration.LAYOUT_BACKOFFICE_SIMPLE,
            category: "general.errors",
            breadcrumb: [
                {name: "general.errors.notfound.title", link: ""},
            ]
        },
        components: {
            default: ErrorNotFound,
        },
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;
