export default class Loadable {
    loaded;

    setLoaded(loaded)
    {
        this.loaded = loaded;
    }

    isLoaded()
    {
        return this.loaded;
    }
}