<template>
  <div>
    <Alert :dismissible="dismissible" v-if="notifications.show.success" :type="notifications.success.length > 0 && 'success'">
      <span class="d-block" :key="message" v-for="message in notifications.success">{{ $t(message) }} </span>
    </Alert>
    <Alert :dismissible="dismissible" v-if="notifications.show.warnings" :type="notifications.warnings.length > 0 && 'warning'">
      <span class="d-block" :key="message" v-for="message in notifications.warnings">{{ $t(message) }} </span>
    </Alert>
    <Alert :dismissible="dismissible" v-if="notifications.show.errors" :type="notifications.errors.length > 0 && 'danger'">
      <span class="d-block" :key="message" v-for="message in notifications.errors">{{ $t(message) }} </span>
    </Alert>
    <div class="notification-box"></div>
  </div>
</template>

<script>
import Alert from "@/components/elements/Alert";

export default {
  name: "NotificationAlert",
  components: {Alert},
  props: {
    notifications: {
      type: Object,
      required: true,
    },
    dismissible: {
      type: Boolean,
      default: false,
    }
  },
}
</script>

<style scoped>

</style>