<template>
  <UserModal class="user-modal" @closemodal="closeUserModal" :user="selectedUser" v-if="selectedUser"/>
  <div class="navbar navbar-expand-md navbar-dark fixed-top">
    <div class="navbar-brand wmin-sm-200">
      <router-link v-if="actionable" :to="{name: 'general.breadcrumb.dashboard'}" class="d-inline-block">
        <Logo />
      </router-link>
      <Logo v-else />
    </div>

    <div v-if="actionable" class="d-md-none align-self-center">
      <button v-if="hasNotifications" class="navbar-toggler dropdown-toggle caret-0" type="button" data-toggle="dropdown">
        <i class="icon-bell2"></i>
      </button>
      <NotificationList v-if="hasNotifications"/>
      <UserMainNavbar v-if="loggedInUser" class="ml-4" :is-mobile="true" @logoutUser="logout" @openUserModal="openUserModal"/>
      <button v-if="hasSideMenuToggle" @click="toggleMobileSideBar" class="ml-4 navbar-toggler sidebar-mobile-main-toggle" type="button">
        <i class="icon-paragraph-justify3"></i>
      </button>
    </div>

    <div v-if="loggedInUser" class="collapse navbar-collapse" id="navbar-mobile">
      <ul v-if="actionable && hasNotifications" class="navbar-nav d-none d-md-block">
        <li class="nav-item dropdown">
          <NotificationButton/>
          <NotificationList/>
        </li>
      </ul>

      <ul class="ml-md-auto mr-md-3 navbar-nav d-none d-md-block">
        <UserMainNavbar @logoutUser="logout" @openUserModal="openUserModal"/>
      </ul>
    </div>
  </div>
</template>

<script>
import NotificationButton from "@/components/notification/NotificationButton";
import NotificationList from "@/components/notification/NotificationList";
import UserMainNavbar from "@/components/user/UserMainNavbar";
import UserModal from "@/components/user/UserModal";
import Logo from "@/components/elements/Logo";
import {SideBarMixin} from "@/mixins/ViewMixin";
import {AuthorizationMixin} from "@/mixins/GeneralMixin";
import {LoadingModalMixin} from "@/mixins/ModalMixin";

export default {
  name: "MainNavbar",
  components: {UserModal, Logo, UserMainNavbar, NotificationList, NotificationButton},
  mixins: [SideBarMixin, AuthorizationMixin, LoadingModalMixin],
  props: {
    actionable: {
      type: Boolean,
      default: true,
    },
    hasSideMenuToggle: {
      type: Boolean,
      default: true,
    },
    hasNotifications: {
      type: Boolean,
      default: true,
    }
  },
  data: () => {
    return {
      selectedUser: null
    }
  },
  methods: {
    openUserModal: function () {
      this.selectedUser = this.loggedInUser;
    },
    closeUserModal: function () {
      let that = this;
      this.renewToken().finally(() => {
        that.selectedUser = null;
      });
    },
    logout: function () {
      let that = this;
      this.queueJob();
      this.logoutToken().finally(() => {
        that.completeJob();
        let props = that.$router.resolve({
          name: 'general.breadcrumb.users.login',
        });
        window.location = props.href;
      });
    }
  }
}
</script>

<style scoped>
.navbar-brand img {
  height: 2rem;
}
.user-modal {
  color: #333;
}
</style>