import Collection from "@/entities/Collection";
import Item from "@/entities/Item";
import Address from "@/entities/Address";
import Cycle from "@/entities/Cycle";
import Moment from "@/entities/Moment";
import Shipment from "@/entities/Shipment";

export default class Order
{
    static newStatuses = ['new', 'modified'];
    static editableStatuses = ['new', 'modified', 'farm_initial_confirmed', 'farm_confirmed'];
    static notLimitedStatuses = ['new', 'modified', 'approved', 'batched'];
    static inProgressStatuses = ['approved', 'batched', 'sent_to_farm', 'farm_initial_confirmed', 'farm_confirmed', 'sent_to_packing', 'packed', 'collected', 'delivery_ready'];
    static finalStatuses = ['delivered', 'not_delivered'];
    static collectedStatuses = ['collected', 'not_delivered'];
    static collectedOrDeliveredStatuses = ['collected', 'delivered'];

    order;
    cycle;
    items;
    address;
    moments;
    shipment;

    isOrderCollected = false;

    constructor(order)
    {
        this.order = order;
        this.cycle = new Cycle(order.cycle);
        this.items = new Collection(order.items.map(item => new Item(item)));
        this.address = new Address(order.address);
        this.moments = new Collection(order.moments.map((moment) => new Moment(moment)));
        this.shipment = order.shipment ? new Shipment(order.shipment) : null;
        this.isOrderCollected = 'collected' === this.getStatus() || 'delivered' === this.getStatus() || 'not_delivered' === this.getStatus();
    }

    getId()
    {
        return this.order.id;
    }

    getTelephone()
    {
        return this.order.telephone;
    }

    getContact()
    {
        return this.order.contact;
    }

    getEmail()
    {
        return this.order.email;
    }

    getInvoice()
    {
        return this.order.invoice;
    }

    hasShipment()
    {
        return this.shipment !== null;
    }

    getShipment()
    {
        return this.shipment;
    }

    getCycle()
    {
        return this.cycle;
    }

    getTotalPriceWithVat()
    {
        return this.order.total_price_with_vat;
    }

    getTotalVat()
    {
        return this.order.total_vat;
    }

    getStatus()
    {
        return this.order.status;
    }

    getCurrency()
    {
        return this.order.currency;
    }

    getComment()
    {
        return this.order.comments;
    }
    hasComment()
    {
        return typeof this.order.comments !== 'undefined' && this.order.comments !== null;
    }

    isNew()
    {
        return Order.newStatuses.includes(this.getStatus());
    }

    isModified()
    {
        return 'modified' == this.getStatus();
    }

    isDelivered()
    {
        return Order.finalStatuses.includes(this.getStatus());
    }

    isInProgress()
    {
        return !this.isNew() && !this.isDelivered();
    }

    isLimited()
    {
        return !Order.notLimitedStatuses.includes(this.getStatus());
    }

    isLimitedAndHasInvalidQty()
    {
        return this.isLimited() && this.getItems().filter((item) => item.getRealQty() > item.getInitialRealQty()).length > 0;
    }

    isEditable()
    {
        return Order.editableStatuses.includes(this.getStatus());
    }

    getItemCollection()
    {
        return this.items;
    }

    getItems()
    {
        return this.items.getItems();
    }

    getItemsForBatch(batchId)
    {
        return this.items.getItems().filter(item => item.getBatchId() == batchId);
    }

    getItemsWithQtyForBatch(batchId)
    {
        return this.getItemsForBatch(batchId).filter(item => item.getQty() > 0);
    }

    getItemsWithQty()
    {
        return this.getItems().filter(item => item.getQty() > 0);
    }

    getItemsWithQtyForBatchOrderByFragile(batchId)
    {
        return this.getItemsWithQtyForBatch(batchId).sort((item1, item2) => {
            if (item1.isFragile()) {
                return 1;
            }

            if (item2.isFragile()) {
                return -1;
            }

            return 0;
        });
    }

    isReadyByBatch(batchId)
    {
        return this.getItemsForBatch(batchId).filter(item => !item.isReady()).length === 0;
    }

    static getNewStatuses() {
        return Order.newStatuses;
    }

    static getInProgressStatuses() {
        return Order.inProgressStatuses;
    }

    static getReadyForBatchingStatuses() {
        return ['approved'];
    }

    static getDeliveredStatuses() {
        return Order.finalStatuses;
    }

    hasChangedItems()
    {
        return this.getChangedItems().length > 0;
    }

    getChangedItems()
    {
        return this.items.getItems().filter(item => item.isChanged());
    }

    getAddress()
    {
        return this.address;
    }

    isPacked()
    {
        return this.getStatus() === 'packed';
    }

    canNotify()
    {
        return !!this.order.can_notify;
    }

    setCanNotify(canNotify)
    {
        this.order.can_notify = !!canNotify;
    }

    getTotalBulk()
    {
        let total = 0;
        this.items.getItems().forEach((item) => {
            if (item.isBulk()) {
                total += item.getRealQty();
            }
        });

        return total;
    }

    getDeliveryMoments()
    {
        return this.moments.getItems().filter((moment) => moment.isDelivery());
    }

    getDeliveryHours()
    {
        return this.getDeliveryMoments().map((moment) => moment.getTime());
    }

    getTotalPacked()
    {
        let total = 0;
        this.items.getItems().forEach((item) => {
            if (!item.isBulk()) {
                total += item.getRealQty();
            }
        });

        return total;
    }

    isCollected()
    {
        return Order.collectedStatuses.includes(this.getStatus());
    }

    isCollectedOrDelivered()
    {
        return Order.collectedOrDeliveredStatuses.includes(this.getStatus());
    }

    setIsOrderCollected(isOrderCollected)
    {
        this.isOrderCollected = isOrderCollected;
    }

    checkIsOrderCollected()
    {
        return this.isOrderCollected;
    }

    getMoments()
    {
        return this.moments;
    }

    getDeliveryMomentsCollection()
    {
        return new Collection(this.getDeliveryMoments());
    }

    getInterval()
    {
        return this.order.interval;
    }
}
