<template>
  <a v-if="hasName" href="#" class="navbar-nav-link d-flex align-items-center dropdown-toggle" data-toggle="dropdown">
    <i class="mr-2 icon-user-check"></i>
    <span>{{ loggedInUser && loggedInUser.getName() }}</span>
  </a>
  <button v-else class="navbar-toggler dropdown-toggle caret-0" type="button" data-toggle="dropdown">
    <i class="icon-user-check"></i> <span>{{ loggedInUser && loggedInUser.getName() }}</span>
  </button>
</template>

<script>
import {AuthorizationMixin} from "@/mixins/GeneralMixin";

export default {
  name: "UserButton",
  mixins: [AuthorizationMixin],
  props: {
    hasName: {
      type: Boolean,
      default: true,
    }
  }
}
</script>

<style scoped>

</style>