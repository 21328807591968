export const SideBarMixin = {
    methods: {
        toggleMobileSideBar: function (event) {
            event.preventDefault();

            let body = document.getElementsByTagName("body")[0];
            body.classList.toggle('sidebar-mobile-main');
        }
    },
}
export const ScrollMixin = {
    methods: {
        scrollToClassElement(elementClass, options = {behaviour: 'smooth'}) {
            this.$nextTick(() => {
                const el = document.getElementsByClassName(elementClass)[0] || null;
                if (el) {
                    el.scrollIntoView(options);
                }
            })
        },
        scrollToTagElement(elementTag, options = {behaviour: 'smooth'}) {
            this.$nextTick(() => {
                const el = document.getElementsByTagName(elementTag)[0];
                if (el) {
                    el.scrollIntoView(options);
                }
            })
        },
        scrollToTop() {
            let that = this;
            setTimeout(function () {
                that.scrollToTagElement('body');
            }, 200);
        },
        scrollToNotificationBox() {
            let that = this;
            setTimeout(function () {
                that.scrollToClassElement('notification-box');
            }, 200);
        },
        scrollToBottomOfModal(modalCss) {
            let modal = document.querySelector(modalCss + '.modal .modal-body');
            this.$nextTick(() => {
                modal.scrollTo({
                    top: modal.scrollHeight,
                    left: 0,
                    behavior: 'smooth'
                })
            })
        }
    },
}