<template>
  <div class="navbar navbar-expand navbar-light fixed-bottom">
    <div class="w-100">
      <div class="text-center d-lg-none w-100">
        <button type="button" class="navbar-toggler dropdown-toggle" data-toggle="collapse"
                data-target="#navbar-footer">
          <i class="icon-unfold mr-2"></i>
          Footer
        </button>
      </div>

      <div class="navbar-collapse collapse" id="navbar-footer">
        <span class="navbar-text" v-if="startYear != currentYear">
         &copy; {{ startYear }} - {{ currentYear }}. <a href="#" class="text-uppercase"> {{ $t('general.company.name') }}</a>
        </span>
        <span class="navbar-text" v-else>
         &copy; {{ startYear }} <a href="#" class="text-uppercase"> {{ $t('general.company.name') }}</a>
        </span>
        <span v-if="config.getAppVersion()" class="navbar-text ml-auto">
         <span class="text-capitalize">{{$t('general.version', {build: config.getAppVersion()})}}</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageFooter',
  data: function () {
    return {
      startYear: 2021
    }
  },
  computed: {
    config: function () {
      return this.$store.state.config;
    },
    currentYear: function () {
      return (new Date().getFullYear());
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
