import Product from "@/entities/Product";
import Packing from "@/entities/Packing";
import Changeable from "@/entities/Changeable";

export default class Item extends Changeable
{
    item;
    product;
    packing;

    constructor(item) {
        super();
        this.item = item;
        this.product = typeof item.product !== "undefined" ? new Product(item.product) : null;
        this.packing = item.packing ? new Packing(item.packing) : null;
    }

    getId()
    {
        return this.item.id;
    }

    getProduct()
    {
        return this.product;
    }

    getRealQty()
    {
        return this.item.real_qty;
    }

    setRealQty(realQty)
    {
        this.item.real_qty = realQty;//parseFloat(realQty);
        this.setChanged(true);
    }

    setQty(qty)
    {
        this.item.qty = qty;//parseFloat(qty);
        this.setChanged(true);
    }

    setConfirmedQty(qty)
    {
        this.item.pivot.confirmed_qty = parseFloat(qty);
        this.setChanged(true);
    }

    getQty()
    {
        return this.item.qty;
    }

    getAssociatedOrdersQty()
    {
        return this.item.pivot.orders_qty;
    }

    getConfirmedQty()
    {
        return this.item.pivot.confirmed_qty;
    }

    getRealConfirmedQty()
    {
        return this.item.pivot.confirmed_qty * (this.hasPacking() ? this.getPacking().getValue() : 1);
    }

    isReviewedAt()
    {
        return this.item.pivot.reviewed_at != null;
    }

    isConfirmedAt()
    {
        return this.item.pivot.confirmed_at != null;
    }

    reviewed()
    {
        this.item.pivot.reviewed_at = new Date();
    }

    confirmed()
    {
        this.item.pivot.confirmed_at = new Date();
    }

    getInitialQty()
    {
        return this.item.initial_qty;
    }

    getInitialRealQty()
    {
        return this.item.initial_real_qty;
    }

    isQtyChanged()
    {
        return this.getQty() !== this.getInitialQty();
    }

    hasNoQty()
    {
        return this.getQty() === 0;
    }

    getTotalBuyingPrice()
    {
        return this.item.total_buying_price;
    }

    getTotalSellingPrice()
    {
        return this.item.total_selling_price;
    }

    getTotalBuyingPriceWithVat()
    {
        return this.item.total_buying_price_with_vat;
    }

    getTotalSellingPriceWithVat()
    {
        return this.item.total_selling_price_with_vat;
    }

    getBatchId()
    {
        return this.item.pivot.batch_id;
    }

    getCurrency()
    {
        return this.item.currency;
    }

    isConfirmed()
    {
        return this.getAssociatedOrdersQty() == this.getConfirmedQty();
    }

    isPartiallyConfirmed()
    {
        return this.getAssociatedOrdersQty() != this.getConfirmedQty() && !this.isUnconfirmed();
    }

    isUnconfirmed()
    {
        return this.getConfirmedQty() == 0 && !this.isConfirmed();
    }

    hasReviewedAt()
    {
        return this.item.pivot.reviewed_at != null;
    }

    hasConfirmedAt()
    {
        return this.item.pivot.confirmed_at != null;
    }

    hasFarmerChecked()
    {
        return this.hasReviewedAt() || this.hasConfirmedAt();
    }

    hasPacking()
    {
        return this.packing != null;
    }

    hasPackingName()
    {
        return this.hasPacking() && null != this.packing.getName() && "" !== this.packing.getName();
    }

    getPacking()
    {
        return this.packing;
    }

    getUnit()
    {
        return this.item.unit ? this.item.unit : this.product.getUnit();
    }

    isReady()
    {
        return this.item.pivot.ready;
    }

    getPackingName(useUnitTypeName = false)
    {
        if (this.hasPackingName()) {
            return this.packing.getName();
        }

        if (useUnitTypeName) {
            return this.getUnitTypeName();
        }

        return '-';
    }

    getUnitTypeName()
    {
        if (this.isBulk()) {
            return 'general.no_packing_bulk';
        }

        return 'general.no_packing';
    }

    isBulk()
    {
        return (this.getUnit() && this.getUnit().toUpperCase()) === 'kg'.toUpperCase();
    }

    getPackedQty()
    {
        if (this.hasPacking()) {
            return this.getQty() / this.getPacking().getValue();
        }

        return this.getQty();
    }

    isFragile()
    {
        return this.getProduct().isFragile();
    }
}