<template>
  <div class="card" v-bind:class="cardStyle">
    <div v-if="hasLogo" class="card-header m-0 pb-0 pt-0">
      <Logo class="d-block mb-2 mt-2" :is-light="false" />
    </div>
    <div v-if="withHeader" v-bind:class="hasLogo && 'mt-0 pt-0'" class="card-header header-elements-md-inline">
      <h3 class="card-title ">
        <slot name="cardtitle">
          <div class="header-elements-inline">
              <slot name="titleimage">
              </slot>
            <span class="verde-title font-weight-semibold">{{ title && $t(title) }}</span>
            <div class="header-elements" v-if="actionable">
              <div class="d-md-none list-icons">
                <a v-if="collapsable" class="list-icons-item" data-action="collapse" @click="toggleCollapse"></a>
                <a v-if="reloadable" class="list-icons-item" data-action="reload"></a>
                <a v-if="reloadable" class="list-icons-item" data-action="remove"></a>
              </div>
            </div>
          </div>
        </slot>
        <slot name="subtitle">
          <span v-if="subtitle" class="d-block font-size-lg text-muted"> {{$t(subtitle)}} </span>
        </slot>
      </h3>
      <slot name="header"></slot>
      <div class="d-none d-md-block header-elements" v-if="actionable">
        <div class="list-icons">
          <a v-if="collapsable" class="list-icons-item" data-action="collapse" @click="toggleCollapse"></a>
          <a v-if="reloadable" class="list-icons-item" data-action="reload"></a>
          <a v-if="reloadable" class="list-icons-item" data-action="remove"></a>
        </div>
      </div>
    </div>

    <div class="msg" v-show="!collapse">
      <div v-bind:class="cardBodyStyle" class="card-body">
        <slot></slot>
      </div>
      <div v-if="withFooter" class="card-footer border-light d-sm-flex justify-content-sm-between d-block text-center align-items-center">
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from "@/components/elements/Logo";
export default {
  name: "Card",
  components: {Logo},
  props: {
    title: {
      type: String,
      default: ""
    },
    subtitle: {
      type: String,
      default: "",
    },
    hasLogo: {
      type: Boolean,
      default: false
    },
    collapsable: {
      type: Boolean,
      default: false
    },
    reloadable: {
      type: Boolean,
      default: false,
    },
    removable: {
      type: Boolean,
      default: false
    },
    withFooter: {
      type: Boolean,
      default: true,
    },
    withHeader: {
      type: Boolean,
      default: true,
    },
    cardBodyStyle: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      collapse: false,
    }
  },
  computed: {
    actionable: function () {
      return this.collapsable || this.reloadable || this.removable;
    },
    cardStyle: function () {
      let style = '';
      if (this.collapsable) {
        style += 'card-collapsed';
      }

      return style;
    }
  },
  methods: {
    toggleCollapse: function(event) {
      // Rotate icon if collapsed by default
      event.target.classList.toggle('rotate-180');
      this.collapse = !this.collapse;
    },
  }
}
</script>

<style scoped>
.verde-title {
  max-width: 552px;
}
.msg {
  transition: all .3s ease;
  padding: 10px;
  overflow: hidden;
}
.msg.v-enter, .msg.v-leave {
  height: 0;
  padding: 0 10px;
  opacity: 0;
}

.card img.logo {
  height: 2rem;
}
</style>
