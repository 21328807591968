import { createI18n } from 'vue-i18n'
import ro from "@/configs/locales/ro.yaml";

const i18n = createI18n({
    locale: 'ro',
    fallbackLocale: 'ro',
    messages: {ro}
});

export default i18n;
