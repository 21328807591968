import Farmer from "@/entities/Farmer";
import Collection from "@/entities/Collection";
import Packing from "@/entities/Packing";
import Stock from "@/entities/Stock";

export default class Product
{
    product;
    farmer;
    packingTypes;
    stock;

    constructor(product) {
        this.product = product;
        this.stock = typeof product.stock !== "undefined" ? new Stock(product.stock): null;
        this.farmer = typeof product.farmer !== "undefined" ? new Farmer(product.farmer): null;
        this.packingTypes = new Collection();
        if (this.product.packings !== null) {
            for (let packing of this.product.packings) {
                this.packingTypes.push(new Packing(packing))
            }
        }
    }

    getId() {
        return this.product.id;
    }

    getUuid() {
        return this.product.uuid;
    }

    getName() {
        return this.product.name;
    }

    getDisplayName() {
        return this.product.display_name;
    }

    getType() {
        return this.product.type.name;
    }

    getBuyingPrice()
    {
        return this.product.buying_price;
    }

    getSellingPrice()
    {
        return this.product.selling_price
    }

    getBuyingPriceWithVat() {
        return this.product.buying_price_with_vat;
    }

    getSellingPriceWithVat() {
        return this.product.selling_price_with_vat;
    }

    getCurrency() {
        return this.product.currency;
    }

    getFarm() {
        return this.farmer;
    }

    getUnit() {
        return this.product.unit;
    }

    getMinBatchQuantity() {
        return this.product.minimum_batch_qty;
    }

    getMinOrderQuantity() {
        return this.product.minimum_order_qty;
    }

    getPackingTypes() {
        return this.packingTypes;
    }
    getStock() {
        return this.stock;
    }

    isFragile() {
        return !!this.product.is_fragile;
    }
}