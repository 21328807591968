import Token from "@/entities/Token";
import VueJwtDecode from "vue-jwt-decode";
import User from "@/entities/User";

export const NotificationsMixin = {
    data: function () {
        return {
            notification: {
                show: {
                    success: false,
                    warnings: false,
                    errors: false,
                },
                success: [],
                warnings: [],
                errors: [],
            }
        }
    },
    computed: {
        globalNotification: function () {
            return this.$store.state.notification;
        },
        canShowNotification: function () {
            return this.notification.show.success || this.notification.show.warnings || this.notification.show.errors;
        },
        canShowGlobalNotification: function () {
            return this.globalNotification.show.success || this.globalNotification.show.warnings || this.globalNotification.show.errors;
        }
    },
    methods: {
        addSuccessNotification: function (message) {
            this.notification.success.push(message);
            this.notification.show.success = true;
        },
        addWarningNotification: function (message) {
            this.notification.warnings.push(message);
            this.notification.show.warnings = true;
        },
        addErrorNotification: function (message) {
            this.notification.errors.push(message);
            this.notification.show.errors = true;
        },
        resetNotifications: function () {
            this.notification.success = [];
            this.notification.warnings = [];
            this.notification.errors = [];
            this.notification.show.success = false;
            this.notification.show.warnings = false;
            this.notification.show.errors = false;
        },
        addGlobalSuccessNotification: function (message) {
            this.$store.commit('successNotification', message);
            this.$store.commit('rerender');
        },
        addGlobalWarningNotification: function (message) {
            this.$store.commit('warningNotification', message);
            this.$store.commit('rerender');
        },
        addGlobalErrorNotification: function (message) {
            this.$store.commit('errorNotification', message);
            this.$store.commit('rerender');
        },
        resetGlobalNotifications: function () {
            this.$store.commit('resetNotification');
            this.$store.commit('rerender');
        }
    },
};
export const ScreenSizeMixin = {
    computed: {
        isScreenLgWidth: function () {
            return this.$store.state.screen.width >= 992;
        },
    },
}

export const AuthorizedActionsMixin = {
    computed: {
        canCreateCycles: function ()  {
            return this.hasFarms();
        },
        canUploadOrders: function () {
            return true;
        },
        canApproveOrders: function () {
            return true;
        },
        canBatchOrders: function () {
            return !this.hasOrAccess(['farmer']);
        },
        canCreateFarm: function () {
            return this.hasOrAccess(['super-admin', 'admin']);
        },
        canUpdateFarm: function () {
            return true;
        },
        canDeleteFarm: function () {
            return this.hasOrAccess(['super-admin', 'admin']);
        },
        canCreateDeliveryNote: function () {
            return this.hasOrAccess(['super-admin', 'admin', 'user']);
        },
        canUpdateBatch: function () {
            return this.hasOrAccess(['super-admin', 'admin', 'user']);
        },
        canUpdateOrdersFromBatch: function () {
            return this.hasOrAccess(['super-admin', 'admin', 'user']);
        },
        canCleanupApplication: function () {
            return this.hasOrAccess(['super-admin']);
        },
        canCleanupOwnApplication: function () {
            return this.hasOrAccess(['super-admin', 'admin']);
        },
        canGenerateStockBatches: function () {
            return this.hasOrAccess(['super-admin', 'admin', 'user'])
        },
        canSendStockBatches: function () {
            return this.hasOrAccess(['super-admin', 'admin', 'user'])
        },
        canUpdateProductsBasedOnStockBatches: function () {
            return this.hasOrAccess(['super-admin', 'admin', 'user'])
        },
        canDeleteEnGrossProducts: function () {
            return this.hasOrAccess(['super-admin', 'admin', 'user'])
        }
    },
    methods: {
        hasOrAccess(roles) {
            return this.$store.state.loggedInUser &&
                this.$store.state.loggedInUser.getToken() &&
                this.$store.state.loggedInUser.getToken().hasRoles(roles);
        },
        hasFarms() {
            return this.$store.state.loggedInUser && this.$store.state.loggedInUser.getFarms().length > 0;
        }
    }
}

export const ErrorHandlerMixin = {
    methods: {
        errorHandler: function (error)
        {
            let statusCode = error.response ? error.response.status : null;
            this.resetNotifications();
            switch (statusCode) {
                case 406:
                    this.addErrorNotification(this.$i18n.t('general.errors.error_format'));
                    break;
                case 500:
                    this.addErrorNotification(this.$i18n.t('general.errors.fatal'));
                    break;
                default:
                    if (error.response && error.response.data.messages) {
                        error.response.data.messages.forEach(message => this.addWarningNotification(message));
                    }
                    break;
            }
        }
    }
}

export const AuthorizationMixin = {
    computed: {
        loggedInUser: function ()
        {
            return null !== this.$store.state.loggedInUser ?
                this.$store.state.loggedInUser : null;
        },
        loggedInToken: function ()
        {
            return this.loggedInUser && this.loggedInUser.getToken();
        },
        bearerToken: function ()
        {
            return this.loggedInToken && this.loggedInToken.getToken();
        },
        authorization: function()
        {
            return this.loggedInToken && this.loggedInToken.getType() + " " + this.bearerToken;
        },
        isLoggedInUserAndValid: function ()
        {
            return this.loggedInUser && this.loggedInUser.isTokenValid();
        }
    },
    methods: {
        hydrateLoggedInUserFromToken: function (userToken)
        {
            let decoded;
            if (!userToken) {
                return false;
            }

            let token = new Token(userToken);

            if (false === token.getToken()) {
                return false;
            }

            try {
                decoded = VueJwtDecode.decode(token.getToken());
            } catch (e) {
                return false;
            }

            if (decoded && decoded.user) {
                token = new Token(userToken, new Date(decoded.exp * 1000), decoded.user.grants || []);
                let user = new User(decoded.user, token);
                user.setLoaded(true);
                this.$cookie.setCookie('user_token', userToken);

                if (typeof this.$store !== 'undefined') {
                    this.$store.commit("login", user);
                }
                return true;
            }

            return false;
        },
        getAuthFromToken: function () {
            if (this.loggedInUser) {
                return;
            }

            let userToken = this.$cookie.getCookie('user_token');
            this.hydrateLoggedInUserFromToken(userToken);
        },
        renewToken: function () {
            let that = this;
            return this.axios.put(this.$store.state.config.getRenewTokenUri()).then(
                response => {
                    let content = response.data;
                    that.hydrateLoggedInUserFromToken(content.data);
                    return Promise.resolve(true);
                }
            ).catch(() => {
                return that.logoutToken();
            });
        },
        logoutToken: function () {
            let that = this;
            return this.axios.delete(this.$store.state.config.getTokenLogoutUri()).then(
                () => {
                    that.$cookie.removeCookie('user_token');
                    that.$store.commit("logout");
                    return Promise.resolve(true);
                }
            ).catch(
                error => {
                    that.$cookie.removeCookie('user_token');
                    that.$store.commit("logout");
                    return Promise.reject(error);
                }
            );
        }
    }
}

export const ProgressMixin = {
    data() {
        return {
            progress: [],
        }
    },
    methods: {
        resetProgress() {
            this.progress = [];
        },
        getProgress() {
            return this.progress.length;
        },
        addProgress(progress) {
            if (!this.progress.includes(progress)) {
                this.progress.push(progress);
            }
        },
        isProgressComplete(maxElements)
        {
            return maxElements === this.getProgress();
        }
    }
}

export const ValidationsMixin = {
    methods: {
        getValidationError: function (type) {
            return this.$i18n.t('validations.'+type);
        }
    }
}

export const DatesMixin = {
    data() {
        return {
            config: this.$store.state.config,
        }
    },
    computed: {
        daysOptions: function () {
            let days = [];
            for (let i = 1; i <= 7 ; i++) {
                days.push({
                    id: i,
                    text: this.formatDay(i),
                });
            }

            return days;
        },
        timeOptions: function () {
            let times = [];
            for (let i = 0; i < 86400; i += 3600) {
                times.push({
                    id: this.formatTime(i),
                    text: this.formatTime(i),
                });
            }
            return times;
        },
    },
    methods: {
        generateDateOptions: function (startAt = null) {
            let now = new Date();
            if (startAt) {
                now.setTime(startAt);
            }
            let dates = [];

            for (let i = 1; i <= 60 ; i++) {
                let current = new Date(now.valueOf());
                current.setDate(current.getDate() + i);
                dates.push({
                    id: current.getTime(),
                    text: this.formatDate(current),
                });
            }

            return dates;
        },
        formatDate: function (date) {
            let dateObj  = date instanceof Date ? date : new Date(Date.parse(date));
            return dateObj.toLocaleDateString(this.config.getLocale())
        },
        formatDateEN: function (date) {
            let dateObj  = date instanceof Date ? date : new Date(Date.parse(date));
            return dateObj.toLocaleDateString('en-US');
        },
        formatDateTime: function (date) {
            let dateObj  = date instanceof Date ? date : new Date(Date.parse(date));
            return dateObj.toLocaleString(this.config.getLocale())
        },
        formatTime: function (incrementInSeconds) {
            try {
                let time = new Date('2021-05-03T00:00:00');
                time.setTime(time.getTime() + incrementInSeconds * 1000);
                return time.toLocaleTimeString(this.$store.state.config.getLocale(), {hour: '2-digit', minute:'2-digit'});
            } catch (e) {
                console.warn(e);
                return '-';
            }
        },
        formatDay: function (dayOfWeek) {
            try {
                let day = new Date('2021-05-03'); // a monday
                day.setDate(day.getDate() + dayOfWeek - 1);
                return (new Date(day)).toLocaleString(this.$store.state.config.getLocale(), {weekday: 'long'})
            } catch (e) {
                console.warn(e);
                return '-';
            }
        },
        formatMoment: function (moment)
        {
            return this.formatDay(moment.getDayOfWeek()) + ', ' + moment.getTime();
        },
        formatMomentDate: function (moment)
        {
            return this.formatDay(moment.getDayOfWeek()) + ', ' + this.formatDate(this.computeMomentDate(moment));
        },
        formatMomentDateTime: function (moment)
        {
            return this.formatDate(this.computeMomentDate(moment)) + ', ' + this.formatMoment(moment);
        },
        computeMomentDate: function (moment)
        {
            let monday = moment.getFixedDate() ? moment.getFixedDate() : new Date();
            let day = monday.getDay() || 7;
            if( day !== 1 ) {
                monday.setHours(-24 * (day - 1));
            }

            if (!moment.getFixedDate() && moment.isNextWeek()) {
                monday.setHours(24*7);
            }

            monday.setHours(24 * (moment.getDayOfWeek()-1));

            return monday;
        },
        formatDays: function (days) {
            return days.map(day => this.formatDay(day))
        },
        sortTimes(times)
        {
            return times.sort((a, b) => parseInt(a.replace(':', '')) - parseInt(b.replace(':', '')));
        },
        computeTimeIncrements(initial, nr, incrementInSeconds = 3600)
        {
            let split = initial.split(':');
            let date = new Date();
            date.setHours(parseInt(split[0]),parseInt(split[1]),0,0);
            let date2 = new Date(date);

            let dates = [new Date(date)];

            for (let i = 0; i < nr; i++) {
                date -= -(incrementInSeconds * 1000);
                date2 -= (incrementInSeconds * 1000);
                dates.push(new Date(date));
                dates.push(new Date(date2));
            }

            return this.sortTimes(dates.map((date) => date.toLocaleTimeString(this.$store.state.config.getLocale(), {hour: '2-digit', minute:'2-digit'})));
        },
        convertLocalDateToUTCExactly(date)
        {
            return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
        }
    }
}

export const StringMixin = {
    methods: {
        ucfirst: function (string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
    }
}

export const DownloadMixin = {
    methods: {
        downloadFileFromBlob: function (fileName, blob) {
            let link = document.createElement("a");
            link.href = URL.createObjectURL(blob);

            link.download = fileName.replace(" ", '_').replace(",", '');

            link.click();
            URL.revokeObjectURL(link.href);
        }
    }
}

export const NumbersMixin = {
    data() {
        return {
            config: this.$store.state.config,
        }
    },
    methods: {
        formatNumber: function (nr) {
            let formatter = new Intl.NumberFormat(this.config.getLocale(), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
            });

            return formatter.format(nr);
        },
        formatQty: function (qty, unit = '', isBulk = true) {
            if (!unit) {
                return this.formatNumber(qty);
            }

            return this.formatNumber(qty) + (isBulk ? ' ' + unit : ' x ' + unit);
        },
        formatPrice: function (qty, currency) {
            try {
                let formatter = new Intl.NumberFormat(this.config.getLocale(), {
                    style: 'currency',
                    currency: currency,
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                });

                return formatter.format(qty);
            } catch (e) {
                console.warn(e);
            }

            return this.formatNumber(qty);
        }
    }
}
