export default class Notification
{
    notification;

    constructor(notification) {
        this.notification = notification;
    }

    getId()
    {
        return this.notification.id;
    }

    getCreatedAt()
    {
        return this.notification.created_at;
    }

    getText()
    {
        return this.notification.text;
    }
}