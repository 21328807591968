<template>
  <div class="page-header mt-3">
    <div class="breadcrumb-line breadcrumb-line-light header-elements-md-inline">
        <Breadcrumb />
    </div>
    <div class="page-header-content header-elements-md-inline">
      <PageTitle />
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/layout/page-header/Breadcrumb";
import PageTitle from "@/components/layout/page-header/PageTitle";
export default {
  name: 'PageHeader',
  components: {PageTitle, Breadcrumb},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
