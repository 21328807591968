<template>
  <li v-if="!isMobile" class="nav-item dropdown dropdown-user">
    <UserButton />
    <div class="dropdown-menu dropdown-menu-right">
      <a @click.stop="openMyAccount" class="dropdown-item"><i class="icon-cog5"></i>{{$t('general.account')}}</a>
      <a @click.stop="logout" class="dropdown-item"><i class="icon-switch2"></i>{{ $t('general.logout') }}</a>
    </div>
  </li>
  <div class="d-inline-block" v-else>
    <UserButton :has-name="false"/>
    <div class="dropdown-menu dropdown-menu-right" id="user_menu">
      <a @click.stop="openMyAccount" class="dropdown-item"><i class="icon-cog5"></i>{{$t('general.account')}}</a>
      <a @click.stop="logout" class="dropdown-item"><i class="icon-switch2"></i>{{ $t('general.logout') }}</a>
    </div>
  </div>
</template>

<script>
import UserButton from "@/components/user/UserButton";

export default {
  name: "UserMainNavbar",
  components: {UserButton},
  emits: ['openUserModal', 'logoutUser'],
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    }
  },
  data: function () {
    return {
      selectedUser: null,
    }
  },
  methods: {
    openMyAccount: function () {
      this.$emit('openUserModal');
    },
    logout: function () {
      this.$emit('logoutUser');
    }
  }
}
</script>

<style scoped>

</style>