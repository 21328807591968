<template>
  <div class="page-content pt-0" v-bind:class="centered ? 'content-boxed' : ''">
    <router-view name="sidebar"/>
    <div class="content-wrapper">
      <div class="content">
        <NotificationAlert :notifications="globalNotification" :dismissible="true"/>
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import NotificationAlert from "@/components/elements/NotificationAlert";
import {NotificationsMixin} from "@/mixins/GeneralMixin";

export default {
  name: 'PageContent',
  components: {NotificationAlert},
  mixins: [NotificationsMixin],
  props: {
    centered: {
      type: Boolean,
      default: false,
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
