import Loadable from "@/entities/Loadable";

export default class Driver extends Loadable
{
    driver;

    constructor(driver = null) {
        super();
        if (null !== driver) {
            this.driver = driver;
        }
    }

    getId()
    {
        return this.driver.id;
    }

    getUserId()
    {
        return this.driver.user_id;
    }

    getName()
    {
        return (this.driver.first_name ?? '' + " " + this.driver.last_name).trim();
    }

    getTelephone()
    {
        return this.driver.telephone;
    }

    getEmail()
    {
        return this.driver.email;
    }
}