import {createStore} from "vuex";
import Configuration from "@/entities/Configuration";

export default createStore({
    state() {
        return {
            config: Configuration.getSingleton(),
            isLoading: false,
            loadingQueueSize: 0,
            rerender: 0,
            breadcrumb: "",
            loggedInUser: null,
            notification: {
                show: {
                    success: false,
                    warnings: false,
                    errors: false,
                },
                success: [],
                warnings: [],
                errors: [],
            },
            cycle: null,
            redirectTo: null,
            afterLoginRedirectTo: null,
            skipResponseCodeCheck: false,

            screen: {
                width: window.innerWidth,
                height: window.innerHeight
            },
        }
    },
    mutations: {
        queueJob: function (state) {
            state.loadingQueueSize++;
            if (state.loadingQueueSize > 0) {
                state.isLoading = true;
            }
        },
        futureRedirect: function (state, value) {
            state.redirectTo = value;
        },
        afterLoginRedirectTo: function (state, value)  {
            if (state.afterLoginRedirectTo === null || value === null) {
                state.afterLoginRedirectTo = value;
            }
        },
        completeJob: function (state) {
            state.loadingQueueSize--;
            if (state.loadingQueueSize <= 0) {
                state.loadingQueueSize = 0;
                state.isLoading = false;
            }
        },
        rerender: function (state) {
            state.rerender++;
            if (state.rerender === Number.MAX_VALUE) {
                state.rerender = 0;
            }
        },
        resizeScreen: function (state) {
            state.screen.width = window.innerWidth;
            state.screen.height = window.innerHeight;
        },
        breadcrumb: function (state, value) {
            state.breadcrumb = value;
        },
        login: function (state, value) {
            state.loggedInUser = value;
        },
        logout: function (state) {
            state.loggedInUser = null;
        },
        errorNotification: function (state, message) {
            state.notification.errors.push(message);
            state.notification.show.errors = true;
        },
        warningNotification: function (state, message) {
            state.notification.warnings.push(message);
            state.notification.show.warnings = true;
        },
        successNotification: function (state, message) {
            state.notification.success.push(message);
            state.notification.show.success = true;
        },
        resetNotification: function (state) {
            state.notification.success = [];
            state.notification.warnings = [];
            state.notification.errors = [];
            state.notification.show.success = false;
            state.notification.show.warnings = false;
            state.notification.show.errors = false;
        },
        refreshActiveCycle: function (state) {
            let event = new Event('init_cycle');
            window.dispatchEvent(event);
            state.rerender++;
        },
        setActiveCycle: function (state, cycle) {
            state.cycle = cycle;
        },
        skipResponseCheckForStatusCode: function (state, status) {
            state.skipResponseCodeCheck = status;
        },
        enableResponseCodeCheck: function (state) {
            state.skipResponseCodeCheck = null;
        },
    },
    actions: {
        download(context, rsp) {

            var blob = new Blob([rsp.data], { type: rsp.type });
            if (navigator.msSaveBlob) { // IE 10+
                navigator.msSaveBlob(blob, 'download.csv');
            } else {
                var link = document.createElement("a");
                if (link.download !== undefined) { // feature detection
                    // Browsers that support HTML5 download attribute
                    var url = URL.createObjectURL(blob);
                    link.setAttribute("href", url);
                    link.setAttribute("download", rsp.name);
                    link.style.visibility = 'hidden';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            }
        },
    }
});
