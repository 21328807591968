import Loadable from "@/entities/Loadable";
import Changeable from "@/entities/Changeable";

export default class User extends Loadable
{

    static ROLE = {
        FARMER: 'farmer',
        SUPERADMIN: 'super-admin',
        ADMIN: 'admin',
        DRIVER: 'driver'
    };

    id;
    name;
    email;
    active = false;
    password;
    copassword;
    token;
    changed;
    roles = [];
    farms = [];
    loginToken;
    forgotToken;

    constructor(user = null, token = null) {
        super();
        this.changed = new Changeable();
        if (user) {
            this.id = user.id;
            this.email = user.email;
            this.name = user.name;
            this.active = !!user.is_active;
            this.roles = user.grants;
            this.farms = user.farms;
            this.loginToken = user.token;
        }

        if (token) {
            this.token = token;
        }
    }

    getRoles()
    {
        return this.roles;
    }

    getFarms()
    {
        return this.farms;
    }

    setId(id)
    {
        this.id = id;
    }

    getId()
    {
        return this.id;
    }

    setName(name)
    {
        this.name = name;
        this.changed.setChanged(true);
    }

    setPassword(password)
    {
        this.password = password;
        this.changed.setChanged(true);
    }

    setCoPassword(password)
    {
        this.copassword = password;
    }

    setEmail(email)
    {
        this.email = email;
        this.changed.setChanged(true);
    }

    getEmail()
    {
        return this.email;
    }

    getName()
    {
        return this.name;
    }

    getPassword()
    {
        return this.password;
    }

    getCoPassword()
    {
        return this.copassword;
    }

    getToken()
    {
        return this.token;
    }

    isTokenValid()
    {
        return this.token && !this.token.isExpired();
    }

    isOfType(role)
    {
        return this.roles.includes(role)
    }

    isOfTypeAdmin()
    {
        return this.isOfType(User.ROLE.ADMIN) || this.isOfType(User.ROLE.SUPERADMIN);
    }

    isOfTypeFarmer()
    {
        return this.isOfType(User.ROLE.FARMER);
    }

    isOfTypeDriver()
    {
        return this.isOfType(User.ROLE.DRIVER);
    }

    hasAccess(roles = [])
    {
        if (roles.length > 0) {
            return this.token.hasRoles(roles);
        }

        return true;
    }

    isChanged()
    {
        return this.changed.isChanged();
    }

    setIsActive(isActive)
    {
        this.active = isActive;
        this.changed.setChanged(true);
    }

    setRoles(roles)
    {
        this.roles = roles;
        this.changed.setChanged(true);
    }

    addRole(role)
    {
        this.roles.push(role);
        this.changed.setChanged(true);
    }

    removeRole(role)
    {
        let index = this.roles.indexOf(role);
        if (index < 0) {
            return;
        }

        this.roles.splice(index, 1);
        this.changed.setChanged(true);
    }

    setFarms(farms)
    {
        this.farms = farms;
        this.changed.setChanged(true);
    }

    isActive()
    {
        return this.active;
    }

    isPasswordMatching()
    {
        if (this.id && !this.password) {
            return true;
        }

        return this.password && this.password === this.copassword;
    }

    reset()
    {
        this.changed.setChanged(false);
    }

    removeFarm(farmId)
    {
        this.farms = this.farms.filter(farm => farm.id !== farmId);
        this.changed.setChanged(true);
    }

    appendFarm(farmId, farmName)
    {
        this.farms.push({id: farmId, name: farmName});
        this.changed.setChanged(true);
    }

    setLoginToken(token)
    {
        this.loginToken = token;
        this.changed.setChanged(true);
    }

    getLoginToken()
    {
        return this.loginToken;
    }

    setForgotToken(value)
    {
        this.forgotToken = value;
    }

    getForgotToken()
    {
        return this.forgotToken;
    }
}