super-admin: "Super Admin"
admin: "Logistics Admin"
farmer: "Fermier"
user: "Logistics User"
driver: "Sofer"
confirmed: "confirmat"
sent_to_packing: "trimis la impachetare"
new: "nou"
in_progress: "in progres"
modified: "modificat"
approved: "aprobat"
batched: "pregatit pt. ferma"
sent_to_farm: "trimisa la ferma"
farm_initial_confirmed: "confirmat initial"
farm_confirmed: "confirmat"
send_to_packing: "trimis la impachetat"
packed: "impachetat"
collected: "colectat"
delivery_ready: "gata de livrare"
delivered: "livrat"
not_delivered: "nelivrat"
prepacking: "pre-impachetare"
re_confirmed: "reconfirmat"
packing: "impachetare"
collection_ready: "gata de colectare"
not_collected: "necolectat"
partial_collected: "partial colectat"
upload: "incarca"
migrated: "migrat"
months:
  select_all: 'Selecteaza tot'
  select_none: 'Deselecteaza tot'
  1: 'Ianuarie'
  2: 'Februarie'
  3: 'Martie'
  4: 'Aprilie'
  5: 'Mai'
  6: 'Iunie'
  7: 'Iulie'
  8: 'August'
  9: 'Septembrie'
  10: 'Octombrie'
  11: 'Noiembrie'
  12: 'Decembrie'
frontoffice:
  breadcrumb:
    farmers:
      batch: "Comanda Noua"
      prepack: "Comanda Confirmata"
      pack: "Instructiuni Impachetare"
      offers:
        dashboard: "Produse disponibile"
        stock-batch: "Disponibilitate produse"
        en-gross: "Produse en-gross"
    drivers:
      collect: "Colectare Comenzi"
      deliver: "Livrare Comenzi"
  shipment:
    contact:
      name: "Nume"
      telephone: "Telefon"
      email: "Email"
      address: "Adresa"
  drivers:
    not_viewable: "Colectarea nu poate incepe deocamdata"
    confirmation:
      collect: "{nr} comenzi necolectate. Sunteti sigur ca doriti sa finalizati colectarea?"
      ship: ""
    summary:
      confirmed: "{nr} comenzi colectate"
      partial: "{nr} comenzi partial colectate"
      unconfirmed: "{nr} comenzi necolectate"
    finish:
      complete: "Procesul curent a fost finalizat"
      thanks: "Va multumim"
    collect:
      title: "Colectare comenzi"
      start: "Incepe colectare"
      batch: "Incarcare"
      collect_day: "Zi Colectare:"
      select_current: "curenta nr. {current} din total {total}"
      summary:
        confirmed: "{nr} comenzi colectate"
        partial: "{nr} comenzi partial colectate"
        unconfirmed: "{nr} comenzi necolectate"
    ship:
      title: "Livrare comenzi"
      start: "Incepe livrare"
      order: "Comanda"
      select_current: "curenta nr. {current} din total {total}"
      ship_day: "Zi Livrare:"
      summary:
        confirmed: "{nr} comenzi livrate"
        partial: "{nr} comenzi partial livrate"
        unconfirmed: "{nr} comenzi nelivrate"
    info:
      collected: "Comanda deja colectata"
      shipped: "Comanda deja livrata"
      quality_issues: "Probleme de calitate"
      is_packing_ok: "Impachetare Ok și documente atașate comenzii ?"
      maps: "click pt a deschide Google Maps"
      order_click: "click pe buton pentru a confirma"
      order_no_click: "click pe buton pentru a nu confirma"
      orders_instructions_collect: "Bifati fiecare comanda pentru a confirma colectarea"
      orders_instructions_ship: "Bifati fiecare comanda pentru a confirma livrarea"
    buttons:
      collect: "Confirm colectarea"
      ship: "Confirm livrarea comenzii"
      next: "Inainte"
      prev: "Inapoi"
      order_collect: "Confirm colectarea comenzii"
      order_not_collected: "Nu am colectat comanda"
  farmers:
    order_nr: "Comanda"
    share:
      button_label: "Invita alti producatori si furnizori"
      share_text: "Eu vând produse prin platforma Verde la prețuri corecte. Ei se ocupă de logistică și colectează comenzile direct de la fermă.\n\nPoți și tu să te înregistrezi în platformă aici: https://www.verdefood.ro/#farmer"
    pack:
      info:
        order_complete: "Va rugam sa atașați documentele necesare comenzii !"
      order:
        order: "Comanda"
        description: "Descriere comanda"
        description_hint: "Bifati fiecare produs dupa impachetare."
        name: "Nume"
        address: "Adresa"
        price: "Total"
        comments: "Comentarii"
        delivery_preference: "Livrare"
        items: "{nr} produse"
        complete: "Process finalizat cu succes"
        thanks: "Va multumim"
        select_current: "curenta nr. {current} din total {total}"
        overview: "Vizualizeaza sumarul de comanda"
        warning_collected: "Comenzile au fost colectate de catre sofer si impachetarea nu a putut fi finalizata"
        warning_collected_subtitle: "Va rugam sa trimiteti facturile pe care nu ati reusit sa le incarcati in platforma la {email}"
      buttons:
        check_packing: "Verifica impachetarea"
        collection: "Finalizeaza impachetare"
        next: "Inainte"
        prev: "Inapoi"
        invoice: "Documentele comenzii"
        upload_invoice: "Incarca document (poza sau fisier PDF)"
        uploaded_invoices: "Documente incarcate:"
        uploaded_empty: "Niciun document incarcat.<br />Va rugam sa incarcati factura!"
        order: "Am impachetat comanda"
        order_item: "Am impachetat"
        order_item_remove: "Nu am impachetat"
        order_item_cancel: "Anuleaza"
        order_complete: "Comanda impachetata"
        unconfirm_order: "Anuleaza impachetare"
    batches:
      confirmed: "Confirmat"
      reviewed: "Verificat"
      not_processed: "Inca neprocesat"
      more_details: "Mai multe detalii"
      less_details: "Mai putine detalii"
      accept_terms: "Accept <a href=\"https://www.verdefood.ro/terms-sellers\" target=\"_blank\">Termenii și Condițiile</a> Verde."
      packing:
        order_count: "{total} comenzi pentru clienti de impachetat"
      title:
        new: "Comanda noua ferma {farmer}, {delivery}"
        new_confirmed: "Va multumim pentru confirmarea comenzii de {delivery}"
        confirmed: "Comanda de {delivery} a fost confirmata"
        prepack: "Comanda confirmata ferma {farm}, {delivery}"
        prepack_reconfirm: "Va multumim pentru pregatirea comenzii de {delivery}"
        reconfirm: "Comanda de {delivery} este in pregatire"
        pack: "Instructiuni impachetare comanda ferma {farm}, {delivery}"
      subtitle:
        prepack: "Va rugam sa reconfirmati comanda"
      modal:
        confirmed: "Va trimitem in scurt timp sumarul de comanda confirmata unde veti putea actualiza cantitatile."
        reconfirmed: "Vizualizeaza sumarul de comanda aici:"
      product_senttofarm_delimiter: "Produse comandate"
      product_prepack_delimiter: "Produse confirmate"
      collect_delimiter: "Detalii colectare si livrare"
      farm_delivery:
        title: "Livrare"
        question: "Puteti livra produsele clientilor pe {date} de la ora {time}?"
        info: "Raspunsul dumneavoastra ne permite sa organizam mai bine livrarea. Veti primi nota de livrare in caz ca livrarea va fi facuta de dumneavoastra."
        confirm: "Da"
        reject: "Nu"
      time:
        packed: "Impachetare"
        collected: "Colectare"
        confirm: "Confirm zi si ora de {type}"
        day: "Zi"
        hour: "Int. orar"
      buttons:
        confirm_start: "Doresc sa confirm comanda"
        unconfirm_all: "Nu am produse momentan"
        confirm: "Confirm {nr} {unit}"
        confirm_send: "Confirma comanda"
        reconfirm_send: "Reconfirma comanda"
        unconfirm: "Nu am acest produs"
        packing_start: "Incepe impachetarea comenzilor"
        view_batch: "Vizualizeaza comanda"
        hide_batch: "Ascunde comanda"
      summary:
        total: "Total"
        collection_time: "Colectare"
        confirmed: "{nr} produse in cantitate totala"
        partial: "{nr} produse in cantitate partiala"
        unconfirmed: "{nr} produse neconfirmate"
        items: "# produse"
        product_price: "Pret unitar"
        total_batch_orders_to_collect: "Comenzi de colectat"
        orders:
          confirmed: "{nr} produse impachetate"
          partial: "{nr} produse in cantitate partiala"
          unconfirmed: "{nr} produse neimpachetate"
        initial_qty: "Comanda initiala: {qty}"
    offers:
      dashboard:
        complete: "Process finalizat"
        thanks: "Va multumim"
        team: "Echipa Verde"
        title: "Produse disponibile {farmer}"
        stock-batch: "Actualizeaza lista pentru comenzi de pe o zi pe alta"
        stock-batch-few: "Trimite oferta ta clientului pentru perioada:"
        request_offer: "Cere Oferta"
        en-gross: "Vinde en-gross"
        stock-batch-summary: "Produse in lista pentru perioada"
        en-gross-summary: "Oferte en-gross"
        en-gross-none: "Nicio oferta en-gross"
        interval: "Perioada"
        button:
          send_availability: "Trimite disponibilitate"
      en-gross:
        modal-title: "Oferta produs"
        title: "Vanzator: {farmer}"
        active_offers_title: "Oferte en-gross active"
        no_offers: "Nu exista nicio oferta valabila inregistrata in sistem"
        confirmation:
          delete_item: "Sunteti sigur ca doriti sa dezactivati aceasta oferta ?"
        item:
          commission_info: "Va facturam comision in valoare de {percent}% din comanda."
          transport_info: "Transportul ramane in responsabilitatea dvs."
          commission_info_example: "Pret la depozitul clientului <span class=\"text-nowrap\">(pret - comision): <strong>{price} / {unit}</strong></span>"
          qty: "Cantitate"
          availability: "Oferta valabila"
          offered_qty: "Cantitate oferita"
          min_qty: "Comanda minima"
          price: "Pret unitar ({currency} / {unit})"
          packing_transport_details: "Detalii ambalare si transport"
          product_details: "Detalii produs"
          packing: "Ambalare produs"
          delivery_area: "Zona de livrare"
          delivery:
            max_100km: "max 100km de ferma"
            romania: "National (Romania)"
            eu: "Spatiul Economic European (UE)"
          euro_pallet: "Posibilitate livrare europalet"
          transport: "Transport cu frig"
          diameter: "Diametru produs"
          diameter_from: "de la"
          diameter_to: "la"
          valid_from: "de la"
          valid_to: "la"
          validity_placeholder: "selecteaza"
          certificates: "Certificari"
          certificates_info: "Introduceti detalii despre certificari si numele autoritatii certificatoare"
          offer_info: "Aceasta nu este o comanda"
          offer_info_2: "Va vom trimite comenzi ferme pentru produsele dvs, in functie de cerere."
          packing_value: "{qty} {unit}"
          no_packing_value: "Nicio ambalare"
          no_diameter_value: "Nu se aplica"
          select_product: "Va rugam selectati un produs"
          image:
            empty: "Nicio poza nu este incarcata"
            listing: "Poza"
            label: "Incarca poza cu produsul"
        button:
          update: "Actualizeaza"
          add_offer: "Adauga oferta"
          update_offer: "Actualizeaza oferta"
          delete: "Sterge"
          add_new_offer: "Adauga o noua oferta en-gross"
          back_to_dashboard: "Am terminat"
          save: "Salveaza ofertele"
      stock-batch:
        title: "Produse disponibile {farmer}"
        description: "Va rugam sa ne confirmati produsele disponibile in perioada:"
        description_desired_price: "Avem o cerere de oferta pentru perioada:"
        existing_products: "Produse disponibile"
        not_existing_products: "Produse indisponibile"
        qty: "Cantitate"
        estimated_qty: "Cantitate"
        weekly: "sapt"
        estimated_price: "Pret vanzare"
        desired_price: "Pret dorit"
        desired_client_price: "Pret dorit de client"
        min_market_price: "Pretul minim din piata: {price} / {unit}"
        commission_info: "Va facturam comision 30% din valoarea comenzii pentru costurile de vanzare si transport."
        farm_price_info: "Pret la poarta fermei <span class=\"text-nowrap\">(pret vanzare - comision): <strong>{farm_price} / {unit}</strong></span>"
        buttons:
          update: "Actualizeaza"
          available: "Am produsul"
          confirm: "Actualizeaza lista"
          confirm_no_changes: "Confirma fara modificari"
          remove_qty: "Nu am acest produs"
          send: "Trimite disponibilitate"
          send_offer: "Trimite oferta"
general:
  form_invalid: "Formularul nu este completat corect, va rugam revizuiti campurile."
  failed: "Actiunea a esuat. Va rugam incercati mai tarziu."
  upload_failed: "Incarcarea fisierului a esuat. Va rugam incercati din nou."
  version: "versiune: {build}"
  cleanup: "curatati total aplicatia"
  cleanup_own: "curatati comenzile aplicatiei"
  fragile: "fragil"
  ok: "ok"
  issues: "probleme"
  yes: "da"
  no: "nu"
  account: "Setari cont"
  unavailable: "indisponibil"
  product:
    not_found: "Produsul nu mai este disponibil"
  select:
    no_results: "Niciun rezultat gasit"
    maxselection: "Doar un singur element poate fi asociat"
  errors:
    fatal: "S-a intamplat o eroare. Va rugam incercati din nou mai tarziu..."
    navigation: "Intorceti-va la pagina precedenta."
    navigation_dashboard: "Inapoi la pagina principala."
    navigation_login: "Logare cu alt utilizator."
    error_format: "Fisierul incarcat este neprocesabil. Va rugam incarcati un alt fisier separat prin virgula."
    error_code: "Cod eroare: {error_code}"
    forbidden:
      title: "Access interzis"
      message: "Nu aveti drepturi sa vizualizati aceasta pagina."
      object: "Din pacate unul sau mai multe elemente de continut nu va sunt accesibile."
    notfound:
      title: "Access interzis"
      message: "Nu aveti drepturi sa vizualizati aceasta pagina."
      object: "Nu au fost gasite unul sau mai multe elemente din continut."
  success: "Actiune realizata cu success"
  active: "Activ"
  activated: "Activat"
  inactive: "Inactiv"
  inactivated: "Dezactivat"
  logout: "Delogare"
  login:
    token: "Token de autentificare"
    title: "Autentificativa in cont"
    post: "Aplicatia se incarca..."
    failed: "Autentificarea a esuat. Credentiale invalide."
    expired: "Sesiunea ta a expirat. Te rugam sa te reloghezi."
    subtitle: "Adaugati credentialele mai jos"
    username: "Utilizator"
    password: "Parola"
    buttons:
      signin: "Autentificare"
      forgot: "Ati uitat parola ?"
      fallback: "Catre pagina principala"
  forgot:
    title: "Resetare credentiale"
    failed: "Autentificarea a esuat. Credentiale invalide."
    subtitle: "Adaugati datele pentru recuperare a parolei mai jos"
    username: "Email"
    password: "Parola noua"
    copassword: "Reintroduceti parola"
    token: "Token de Resetare"
    generate_success: "Veti primi un email cu tokenul de resetare al parolei"
    generate_failed: "Ceva neprevazut s-a intamplat. Va rugam sa incercati mai tarziu"
    reset_success: "Parola a fost schimbata cu success"
    reset_failed: "Ceva neprevazut s-a intamplat. Va rugam sa incercati mai tarziu"
    buttons:
      generate: "Genereaza token"
      reset: "Reseteaza"
      login: "Inapoi la pagina de login"
  loading: "Se incarca"
  progress: "Progres {nr}%"
  no_packing: "buc."
  no_packing_bulk: "pachete"
  immutable: "Comanda nu poate fi modificata momentan"
  editable: "Comanda poate fi modificata"
  url_copy: "Apasati copiaza pentru a copia link-ul"
  url_copied: "Copiat !"
  bulk: "vrac"
  sidebar:
    title: "Menu"
  confirmation:
    action: "Sunteti sigur ca doriti sa {action} ?"
  buttons:
    cancel: "Anuleaza"
    confirm: "Confirma"
    close: "Inchide"
    cleanup: "Curata aplicatia"
    cleanup_own: "Curata comenzi"
  status:
    success: "Succes"
    warning: "Eroare"
    failed: "Esuat"
    warnings: "Erori"
  company:
    name: "VerdeFood"
  pagination:
    next: "Inainte"
    prev: "Inapoi"
  breadcrumb:
    home: "Acasa"
    administration: "Administrare"
    management: "Management"
    dashboard: "Sumar operatiuni"
    stocks:
      batches:
        list: "Disponibilitate"
        edit: "Disponibilitate produse {additional}"
    deliverynotes:
      list: "Note de livrare"
    users:
      login: "Autentificare"
      forgot: "Recuperare parola"
      list: "Utilizatori"
      edit: "Editare"
    cycles:
      list: "Detalii Logistice"
    farmers:
      list: "Ferme"
      edit: "{additional}"
    products:
      list: "Produse"
      edit: "Editare"
    orders:
      list: "Comenzi Clienti"
      edit: "Editare comanda: {additional}"
      upload: "Incarcare"
    batches:
      list: "Comenzi Fermieri"
      edit: "Editare comanda: {additional}"
      generate: "Generare Comenzi Fermieri"
    notifications:
      list: "Notificari"
en_gross:
  edit:
    empty_product: "Nu exista momentan nicio oferta asociata fermei curente"
    confirm:
      delete: "Sunteti sigur ca doriti sa stergeti aceasta oferta ?"
    details:
      id: "ID"
      farmer: "Fermier"
      product: "Produs"
      qty: "Cantitate"
      min_qty: "Cantitate minima"
      price: "Pret"
      validity: "Perioada"
      diameter: "Diametru"
      packing: "Impachetare"
      certificates: "Certificate"
      delivery_area: "Zona de livrare"
      delivery_euro_pallet: "Livrare euro palet ?"
      delivery_cooler: "Livrare cooler ?"
      sku: "SKU"
    id: "ID"
    title: "Oferte"
    product: "Produs"
    qty: "Cantitate"
    price: "Pret"
    validity: "Perioada"
    actions: "Actiuni"
    buttons:
      delete: "Sterge"
farmers:
  active: "Activ"
  inactive: "Inactiv"
  import: "Import"
  total_products: "Total {nr}"
  available_products: "Disponibile {nr}"
  product_categories: "Categorii"
  en_gross_products_active: "Active {nr}"
  cycles:
    modal:
      send_to_collection: "T5: Zi de colectare"
  modal:
    create_title: "Adaugare producator"
    edit_title: "Actualizare producator {farmer}"
    group_info: "Informatii generale"
    group_company: "Detalii comerciale"
    group_products: "Detalii produse"
    group_certifications: "Certificari"
    group_operations: "Detalii operationale"

    products: "Incarca lista de produse"
    show_commission_details: "Afiseaza detalii comision"
    sells_import_products: "Vinde produse importate"
    logo:
      upload_label: "Incarca Logo"
      empty: "Nicio poza nu este incarcata"
      listing: "Logo"
    delivery_area:
      local: "Local - max 100km de ferma"
      national: "National (incl. local)"
      eu: "UE (incl. national si local)"
    legal_type:
      srl: "SRL"
      pfa: "PFA"
      individual: "Individual"
      atestat: "Atestat Producator"
      other: "Alta forma juridica"
    placeholder:
      alias: "Alias"
      brand_name: "Brand"
      description: "Descriere Producator"
      address: "Adresa"
      contact: "Telefon"
      email: "Email"
      country: "Tara"
      county: "Judet/Regiune/Zona"
      active: "Activat / Dezactivat"
      products_description: "Descriere produse"
      bio: "Produse Bio"
      mountain: "Produse montane"
      product_categories: "Categorii de produse"
      products_availability: "Disponibilitate produse"
      production_capacity: "Capacitate de productie"
      certifications: "Alte Certificari"
      company_name: "Nume Companie"
      company_address: "Adresa Companie"
      legal_type: "Tip Producator"
      vat: "TVA (%)"
      cui: "CUI (Numar inregistrare)"
      min_order_local: "Comanda minima local (RON)"
      min_order_national: "Comanda minima national (RON)"
      min_order_eu: "Comanda minima UE (RON)"
      delivery_area: "Zona de livrare"
  buttons:
    create: "Adauga o noua ferma"
    update: "Actualizeaza"
    delete: "Stergere"
    edit: "Vizualizeaza fermier"
    cycle: "Detalii ciclu logistic"
    download: "Descarca lista de produse"
  list:
    title: "Ferme existente"
    name: "Ferma"
    products_count: "# Produse"
    categories: "Categorii"
    delivery_days: "Zile de colectare"
    last_update: "Ultima actualizare"
    status: "Status"
    empty: "Nicio ferma creata momentan"
    details:
      id: "ID"
      name: "Ferma"
      status: "Status"
      products_count: "# Produse"
      delivery_days: "Zile de livrare"
      last_update: "Ultima actualizare"
    confirmation:
      delete: "stergeti aceasta ferma"
  edit:
    name: "Ferma"
    products_count: "# Produse"
    product_categories: "Categorii"
    en_gross_products_count: "# Oferte"
    delivery_days: "Zile de colectare"
    last_update: "Ultima actualizare"
    status: "Status"
    uuid: "Identificator"
    id: "ID"
    product: "Produs"
    unit: "Unitate"
    unit_price: "Pret unitate"
    stock: "Cantitate"
    packing: "Impachetare"
    min_order: "Comanda minima"
    empty_product: "Nu exista momentan niciun produs asociat fermei curente"
    details:
      uuid: "Identificator"
      product: "Produs"
      unit: "Unitate"
      unit_price: "Pret unitate"
      packing: "Impachetare"
      min_order: "Comanda minima"
batches:
  modal:
    title:
      in_review: "Comanda aprobata de confirmat - Ferma {farmer}"
      in_confirmation: "Instr. impachetare - Ferma {farmer}"
      in_prepacking: "Comanda confirmata - Ferma {farmer}"
    status:
      in_review: "Comanda de confirmat de ferma la link-ul:"
      in_confirmation: "Instructiuni de impachetare la link-ul:"
      in_prepacking: "Comanda confirmata de ferma la link-ul:"
  buttons:
    generate: "Creaza comenzi ferme"
    confirm_all: "Confirma tot"
    delete_all: "Sterge tot"
    delete: "Sterge"
    send_all: "Trimite tot"
    send_instructions: "Trimite instructiuni"
    send: "Trimite"
    back: "Inapoi"
    confirm: "Confirma"
    copy: "Copiaza"
    show: "Link trimis"
    edit: "Vezi detalii"
    generate_batches: "Genereaza comenzi fermieri"
  list:
    title: "Comenzi actuale ferme"
    subtitle: "Data comenzi clienti ciclu activ: {order_batch}"
    empty: "Nicio comanda pentru ferme momentan"
    total_existing: "Aveti {total} comenzi pentru ferme in acest moment"
    contact: "Contact"
    name: "Ferma"
    total: "Total"
    delivery_estimation: "Data colectare estimata"
    status: "Status"
    order_plan_notice: "Exista {nr} de comenzi de la clienti de planificat cu fermele"
    batch_plan_notice: "Exista {nr} comenzi noi pentru ferme de aprobat"
    action: "Actiune"
    cycle:
      name: "Ciclu livrare: {name}"
      order_batch: "Data comenzi clienti: {order_batch_date}"
      deliver_order: "Data livrare urmatoare: {deliver_order_date}"
    details:
      name: "Nume"
      farm: "Ferma"
      id: "Comanda #"
      status: "Status"
      total: "Total"
      comments: "Comentarii"
      price: "Total"
      items: "{nr} produse"
      delivery_estimation: "Colectare estimata"
      total_weight: "Greutate"
    confirm:
      confirm: "confirmati aceasta comanda"
      confirm_all: "confirmati toate comenzile"
      delete: "stergeti comanda selectata"
      generate: "generati comenzile pentru ferme"
      send_all: "trimiteti toate comenzile catre fermieri"
      send: "trimiteti comanda catre fermier"
      send_all_instructions: "trimiteti instructiunile de ambalare pentru toate comenzile"
      send_instructions: "trimiteti instructiunile de ambalare pentru aceasta comanda"
      prepack: "trimiteti instructiunile de pre-ambalare pentru comanda"
  edit:
    delivery: "Colectare: {moment_date}"
    batch_item_total_bulk: "{total}# total greutate in kg."
    batch_item_total_pack: "{total}# total pachete"
    title: "Comanda fermier: {farmer}"
    total_price_with_vat: "Total"
    total_vat: "TVA"
    product: "Produs"
    real_quantity: "Cantitate"
    packing: "Impachetare"
    price: "Pret"
    quantity: "# pachete"
    collection_day: "Zi colectare"
    actions: "Actiuni"
    weight: "Greutate {bulk} kg vrac + {pack} pachete"
    farmer_can_deliver: "Fermierul poate livra"
    initial_qty: "Initial"
    details:
      name: "Nume produs"
      farmer: "Ferma"
      qty: "Cantitate"
      price: "Total"
      total: "Total"
      item_total: "Pret"
      delivery_estimation: "Colectare estimata"
      packing: "Impachetare"
      collection_day: "Zi colectare"
    orders:
      title: "Comanda confirmata ferma {farm}, {delivery}"
      initial_qty: "Initial"
      contact: "Contact"
      name: "Nume"
      qty: "Cantitate"
      total: "Total"
      delivery_estimate: "Estimat Livrare"
      status: "Status"
      empty: "Nicio comanda client asociata acestui tip de produs."
      confirm: "Actualizeaza comenzi"
      update: "actualizezi comenzile"
    confirm:
      confirm: "confirmati comanda"
      force_reconfirm: "reconfirmati automat comanda"
      update: "actualizati comanda"
      send: "trimiteti comanda"
      prepack: "trimiteti instructiunile de pre-ambalare pentru comanda"
      pack: "trimiteti instructiunile de impachetare pentru comanda"
      send_instructions: "trimiteti instructiunile de ambalare pentru aceasta comanda"
    buttons:
      confirm: "Confirma comanda"
      force_reconfirm: "Reconfirma comanda"
      update: "Actualizeaza comanda"
      send: "Trimite comanda"
      shipment: "Detalii de colectare"
orders:
  cycles: "Selecteaza ciclul"
  list:
    title: "Comenzi clienti curente"
    subtitle: "Data comenzi clienti ciclu activ: {order_batch}"
    empty: "Nicio comanda de la clienti in acest moment"
    new: "Noi ({nr})"
    inprogress: "In pregatire ({nr})"
    delivered: "Livrate ({nr})"
    contact: "Contact"
    name: "Nume"
    total: "Total"
    delivery_estimation: "Data livrare estimata"
    status: "Status"
    filters:
      delivered_from: "Livrat de la"
      delivered_to: "Livrat pana la"
      status: "Doar \"livrat\""
      customer: "Client"
      farmer: "Ferma"
    confirm:
      delete: "stergeti toate comenzile"
      partial: "confirmati partial"
      full: "confirmati toate comenzile"
      cycle_end: "inchideti ciclul curent"
    details:
      name: "Nume"
      id: "Comanda #"
      status: "Status"
      total: "Total"
      comments: "Comentarii"
      price: "Total"
      items: "{nr} produse"
      delivery_estimation: "Livrare estimata"
      total_weight: "Greutate"
  edit:
    weight: "Greutate {bulk} kg vrac + {pack} pachete"
    order_item_total_bulk: "{total}# total greutate in kg."
    order_item_total_pack: "{total}# total pachete"
    title: "Comanda client: {customer}"
    order_number: "Comanda nr. {number}"
    products: "Produse"
    empty: "Niciun produs nu este asociat acestei comenzi"
    no_documents_warning: "Nicio factura atasata, rugati fermierul sa o trimita la {email}."
    view_details: "Verfica Comanda"
    product: "Produs"
    products_total: "Total produse #{total}"
    real_quantity: "Cantitate"
    farm: "Ferma"
    price: "Pret"
    quantity: "# pachete"
    initial_quantity: "Cantitate initiala"
    collection_day: "Zi colectare"
    total_price_with_vat: "Total"
    total_vat: "TVA"
    invoice: "Documente"
    invoices_available: "Documente incarcate"
    no_invoices: "Nu au fost atasate documente"
    download_invoice: "Descarca fisier"
    upload_invoice: "Incarca fisier"
    uploaded_invoices: "Documente incarcate"
    uploaded_empty: "Nu au fost incarcate documente"
    modify_invoice: "Modifica"
    cancel_invoice: "Anuleaza"
    save_invoice: "Salveaza"
    product_type: "Tip produs"
    delivery: "Livrare: {moment_date}"
    delivered: "Livrata"
    not_delivered: "Nelivrata"
    confirm:
      confirm: "confirmati comanda"
      update: "actualizati comanda"
    buttons:
      confirm: "Confirma comanda"
      update: "Actualizeaza comanda"
      shipment: "Detalii de livrare"
      documents: "Documente"
    details:
      name: "Nume produs"
      farmer: "Ferma"
      qty: "Cantitate"
      initial_qty: "Cantitate initiala"
      price: "Pret"
      total: "Total"
      delivery_estimation: "Livrare estimata"
  buttons:
    show_existing_orders: "Vezi comenzi clienti curente"
    confirm_partial: "Confirma comenzi livrare urmatoare"
    confirm_partial_small: "Confirma livrari urmatoare"
    confirm_all: "Confirma toate comenzile"
    delete_all: "Sterge comenzi vizibile"
    edit: "Vezi detalii"
    upload: "Incarca comenzi"
    cycle_close: "Inchide ciclul activ"
    cycle_start: "Porniti ciclul activ"
    filter: "Filtreaza"
    download: "CSV"
  upload:
    title: "Va rugam sa incarcati o lista noua de comenzi clienti"
    delivery: "Comenzile curente de la clienti vor fi livrate: {deliver_order}"
    input:
      placeholder: "Format acceptat: .csv"
      success_status: "Succes"
      warning_status: "Esuat"
    modified: "{nr} comenzi clienti au fost modificate."
    error: "{nr} comenzi clienti nu au putut fi create."
    count: "Au fost importate {nr} comenzi"
dashboard:
  weekly_charts: "Valori saptamanale"
  title: "Sumar operatiuni"
  created_orders: "Data comenzi clienti"
  delivery_orders: "Data livrare urmatoare"
  total_orders_current_cycle: "# comenzi pe ciclul curent"
  total_orders_current_week: "# comenzi saptamana curenta"
  total_delivered_orders: "# comenzi livrate clientilor in total"
  total_delivered_batches: "# comenzi efectuate cu fermele in total"
  customer_orders: "Comenzi clienti"
  farmer_orders: "Comenzi fermieri"
  statuses:
    orders:
      new: "Noi"
      confirmed: "Confirmate"
      sent_to_farm: "Trimise la ferma"
      in_preparation: "In Pregatire"
      ready_for_delivery: "Gata de Livrare"
      delivered: "Livrate"
    batches:
      new: "Noi"
      confirmed: "Confirmate"
      in_preparation: "In Pregatire"
      collection_ready: "Gata de Colectare"
      collected: "Colectate"
users:
  role: "Rol: {role}"
  active: "Activ"
  inactive: "Inactiv"
  list:
    title: "Management utilizatori"
    short_title: "Utilizatori"
    name: "Nume utilizator"
    roles: "Roluri"
    email: "eMail"
    status: "Status"
    empty: "Niciun utilizator creat momentan"
  modal:
    create_title: "Va rugam sa introduceti informatiile utilizatorului"
    edit_title: "Va rugam sa actualizati informatiile utilizatorului {user}"
    token_info: "Incepe cu o litera"
    farmer_driver_checked: "Este si sofer"
    farmer_driver_unchecked: "Nu este si sofer"
    same_roles_editing_not_allowed: "Nu este permisa editarea unui utilizator de acelasi rang sau mai mare"
    placeholder:
      name: "Nume"
      password: "Schimbati parola existenta"
      copassword: "Confirmati noua parola"
      new_password: "Parola"
      new_copassword: "Confirmare Parola"
      email: "eMail"
      active: "Activat / Dezactivat"
      roles: "Roluri"
      farms: "Ferme disponibile"
      farm_token: "Token de access fermier"
  buttons:
    create: "Creaza"
    update: "Actualizeaza"
cycles:
  list:
    title: "Detalii Logistice"
    empty: "Nu exista niciun ciclu logistic definit in acest moment"
    info_no_farms: "Va rugam sa adaugati minim o ferma pentru a defini detaliile logistice"
    name: "Ciclu logistic"
    process_order_day: "Procesare comenzi client"
    delivery_order_day: "Livrare comenzi clienti"
    info: "Va rugam definiti detaliile de livrare"
    confirm:
      delete: 'stergeti acest ciclu logistic'
  modal:
    subtitle: "Va rugam sa definiti intervalele orare crescator cronologic, (T1 este definit dupa T0)"
    create_title: "Creaza un nou ciclu logistic"
    edit_title: "Actualizeaza ciclul logistic {name}"
    process_order: "T0: Zi de procesare comenzi clienti"
    process_order_time: "Pana la ora"
    send_to_farm_batch: "T1: Zi de trimis comenzi la ferme pt confirmare"
    send_to_farm_batch_time: "la ora"
    prepack_batch: "T2: Zi de trimis comenzi confirmate la ferme"
    prepack_batch_time: "la ora"
    pack_batch: "T3: Zi de trimis instr. impachetare la ferme"
    pack_batch_time: "la ora"
    send_to_collection: "T4: Zi de trimis note colectare si livrare la sofer"
    send_to_collection_time: "la ora"
    collection: "T5: Zi de colectat"
    collection_time: "de la ora"
    delivery_order: "T6: Zi livrat comenzi"
    delivery_order_time: "de la ora"
    placeholder:
      name: "Nume ciclu"
      process_order: "Selecteaza zi"
      process_order_time: "Selecteaza ora"
      send_to_farm_batch: "Selecteaza zi"
      send_to_farm_batch_time: "Selecteaza ora"
      prepack_batch: "Selecteaza zi"
      prepack_batch_time: "Selecteaza ora"
      pack_batch: "Selecteaza zi"
      pack_batch_time: "Selecteaza ora"
      send_to_collection: "Selecteaza zi"
      collection: "Selecteaza zi"
      send_to_collection_time: "Selecteaza ora"
      collection_time: "Selecteaza ora"
      delivery_order: "Selecteaza zi"
      delivery_order_time: "Selecteaza ora"
  buttons:
    create: "Adauga alt ciclu"
    create_small: "Creaza"
    update: "Actualizeaza"
    edit: "Editare"
    delete: "Stergere"
stocks:
  batches:
    existing:
      product_selection: "Selectie"
      qty: "Cantitate"
      estimated_qty: "Cantitate estimata"
      weekly: "sapt"
      estimated_price: "Pret unitar"
      desired_price: "Pret dorit"
      list_price: "Pret lista"
    generate:
      customer_name: "Nume client"
      interval: "Perioada"
      start_at: "de la"
      end_at: "la"
      farm: "Alegeti ferma"
      all_products: "Toate produsele"
      product: "Produse"
      placeholder:
        start_at: "Data"
        end_at: "Data"
    buttons:
      send: "Trimite"
      copy: "Copiere"
      edit: "Editare"
      link: "Link"
      show: "Link"
      migrate: "Actualizeaza produse existente"
      generate:
        all: "Cere informatii de la toate fermele"
        single: "Cere informatii la o ferma"
        offers: "Cere oferte la un vanzator"
        create_all: "Creaza comenzi de stoc"
        configure_next: "Configureaza"
        prev: "Inapoi"
        next: "Urmatorul produs"
        create: "Creaza comanda de stoc"
        create_simple: "Creaza"
        create_offer_request: "Creaza cerere de oferta"
    list:
      empty: "Nicio cerere de disponibilitate nu a fost momentan gasita"
      id: "ID"
      title: "Disponibilitate produse"
      farm: "Ferma"
      available_products: "# produse"
      created_at: "Creat la"
      completed_at: "Raspuns la"
      customer_name: "Nume client"
      offers: "Cerere de oferta"
      status: "Status"
      action: "Actiune"
      interval: "Perioada"
      status_in_progress_title: "Comanda de stock trimisa fermei: {farmer}"
      status_in_progress_title_offers: "Cerere de oferta trimisa producatorului: {farmer}"
      status_in_progress_body: ""
      confirm:
        send: "trimiteti comanda de stoc fermierului"
    edit:
      id: "ID"
      available_products: "Produse disponibile"
      new_products: "Produse noi"
      harvest_products: "Recolta"
      empty: "Nu a fost gasit niciun produs"
      title: "Disponibilitate produse: {farmer}"
      title_offers: "Cerere oferta {farmer}"
      customer_name: "Nume client"
      product: "Produs"
      real_quantity: "Cantitate disponibila"
      real_price: "Pret lista"
      desired_price: "Pret dorit"
      weekly: "sapt"
      created_at: "Creat la"
      completed_at: "Raspuns la"
      status: "Status"
      action: "Actiune"
      interval: "Perioada"
      status_new_title: ""
      status_new_body: ""
      migrate_success: "Migrarea cantitatilor si preturilor catre produse realizata cu success"
      migrate_failed: "Migrarea cantitatilor si preturilor catre produse a esuat"
deliverynotes:
  shipments:
    modal:
      title_delivery: "Informatii livrare comanda"
      title_collecting: "Informatii colectare comanda"
      awb: "AWB"
      driver: "Sofer"
      is_packing_ok: "Impachetare"
      comments: "Comentarii"
  batch_nr: "comanda nr. {nr}"
  list:
    action: ""
    title: "Note de livrare"
    subtitle: "Data comenzi clienti ciclu activ: {order_batch}"
    name: "Nume"
    status: "Status"
    created_at: "Creata la"
    empty: "Nicio nota de livrare nu este creata momentan"
    delivery_estimation: "Estimat livrare"
    delivered_at: "Livrat la"
    driver: "Sofer"
  modal:
    status:
      new: "Nota de livrare de colectat la link-ul:"
      in_progress: "Nota de livrare de livrat la link-ul:"
      delivered: "Nota de livrare completa la link-ul:"
    title:
      new: "Informatii nota de livrare {name}"
      in_progress: "Informatii nota de livrare {name}"
      delivered: "Informatii nota de livrare {name}"
    create_title: "Creaza o nota de livrare"
    placeholder:
      name: "Nume"
      batches: "Selecteaza comenzile de ferme"
      driver: "Selecteaza soferul"
  buttons:
    create: "Creaza nota noua"
    create_info_no_cycle: "Un ciclu activ este necesar in sistem pentru a permite crearea unei noi note"
    create_info_no_farms: "Va rugam sa adaugati minim o ferma pentru a defini detaliile logistice"
    show: "Link trimis"
    copy: "Copiaza"
notifications:
  list:
    title: "Notificari"
    text: "Mesaj"
    created_at: "Creat la"
    empty: "Nu exista momentan nicio notificare pentru dumneavoastra in sistem"
    latest: "Ultimele notificari primite"
validations:
  decimal: "Acest camp trebuie sa fie numeric"
  required: "Acest camp este obligatoriu"
  email: "Acest camp trebuie sa aiba formatul unui email"
  numeric: "Acest camp trebuie sa fie numeric"
  telephone: "Telefonul trebuie sa fie in format international"
  sameAsPassword: "Parolele nu coincid"
  passwordMinLength: "Parola trebuie sa contina minim 6 caractere"
  roleMaxLength: "Un singur rol poate fi asignat acestui utilizator"
  customFarmerRoleMaxLength: "Un singur rol poate fi asignat acestui utilizator"
  farmerHasOneFarm: "Utilizatorul de tip fermier poate sa aiba o singura ferma asociata"
  userTokenFormat: "Tokenul de utilizator trebuie sa inceapa cu o litera"
  priceMinValue: "Pretul trebuie sa fie mai mare ca 0 RON"
  qtyMinValue: "Cantitatea trebuie sa fie mai mare decat 0 kg"
  minQtyMinValue: "Cantitatea minima trebuie sa fie mai mare decat 0 kg"
  diameterToGteDiameterFrom: "Diametrul maxim trebuie sa fie mai mare sau egal decat cel minim"
  qtyGteMinQty: "Cantitatea oferita trebuie sa fie mai mare sau egala decat comanda minima"
