import Changeable from "@/entities/Changeable";

export default class Company extends Changeable
{
    company_name;
    company_address;
    vat;
    cui;
    legal_type = 'srl';

    constructor(name = null) {
        super();
        this.company_name = name;
    }

    getCompanyName()
    {
        return this.company_name;
    }

    setCompanyName(name)
    {
        this.company_name = name;
        this.setChanged(true);
    }

    getCompanyAddress() {
        return this.company_address;
    }

    setCompanyAddress(address) {
        this.company_address = address;
        this.setChanged(true);
    }

    getVat() {
        return this.vat;
    }

    setVat(vat) {
        this.vat = vat;
        this.setChanged(true);
    }

    getCui() {
        return this.cui;
    }

    setCui(cui) {
        this.cui = cui;
        this.setChanged(true);
    }

    getLegalType() {
        return this.legal_type;
    }

    setLegalType(legal_type) {
        this.legal_type = legal_type;
        this.setChanged(true);
    }

    isSet()
    {
        return typeof this.company_name !== 'undefined';
    }
}
